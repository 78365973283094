import { Component, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from '../_services/usuario.service';
import { VoucherService } from '../_services/voucher.service';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { MarketplaceService } from '../_services/marketplace.service';
import { ProductsService } from '../_services/products.service';
import { ServicoGiftService } from '../_services/servico-gift.service';
import { EstabelecimentoService } from '../_services/estabelecimento.service';
import { CategoriaEstabelecimentoService } from '../_services/categoria-estabelecimento.service';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit {
  //Modais
  @ViewChild('modalComoFunciona') modalComoFunciona;
  //
  public URLNova = "https://img.pipooh.com.br/"
  pesquisaMamae: any;
  closeResult = '';
  tipo: any = "gestante";
  semanas: any = [];
  semana: any;
  dataNascimentoCrianca: any;
  nomeCrianca: any;
  sexoCrianca: any;
  agree: any;
  politica: any;
  checkCodigo: any;
  nome: any;
  email: any;
  senha: any;
  cpf: any;
  senhaC: any;
  celular: any;
  ultimaMenstruacao: any;
  codigo: any;
  apresentacao: any = "mamãe";
  user: any = {};
  banners: any = [{ "imagem": '../../assets/img/banners/Cards_desk.jpg' }, { "imagem": '../../assets/img/banners/EVENTOS.jpg' }, { "imagem": '../../assets/img/banners/QUEMUSA.jpg' }, { "imagem": '../../assets/img/banners/NAMIDIA.jpg' }];
  pageProdutos = 1;
  pageLojas = 1;
  pageAnuncios = 1;
  pageServicos = 1;
  pageSize = 8;
  collectionSize = [];
  selected = new FormControl(0);
  // estabelecimento
  estabelecimentos: any = [];
  estabelecimentoImagem: any = [];
  categoriasEstabelecimento: any[];
  categoriaSelecionada: any = "";
  //produtos
  products: any = [];
  categorys: any = "";
  product: any = [];
  more: boolean = false;
  chosenImg: any;
  imagens: any;
  totalProdutos: any = 0;
  //busca
  buscaProduto: any = { "categoria": "", "loja": "", "genero": "", "ordem": "" };
  buscar: any = "";
  //loading
  loading: any = false;
  loadingProdutos: any = false;
  loadingLojas: any = false;
  loadingAnuncios: any = false;
  loadingServicos: any = false;
  //anuncios
  anunciosVendas: any = [];
  anunciosDoacao: any = [];
  anuncios: any = [];
  categoriasAnuncio: any = [];
  ufs: any = [];
  cidades: any = [];
  buscaAnuncio: any = { "categoria": "", "uf": "", "cidade": "", "tipo": "" };
  //servicos
  servicos: any = [];
  imagesDesktop = ['../../assets/img/banners/Cards_desk1.jpg', '../../assets/img/banners/Numeros.jpg', '../../assets/img/banners/cadastradas.jpg', '../../assets/img/banners/midia.jpg'];
  imagesMobile = ['../../assets/img/banners/COMOFUNCIONA.jpg', '../../assets/img/banners/EVENTOS.jpg', '../../assets/img/banners/QUEMUSA.jpg', '../../assets/img/banners/NAMIDIA.jpg'];
  public isCollapsed = false;
  public isCollapsed2 = false;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private _router: Router,
    private toastr: ToastrService,
    private _voucherService: VoucherService,
    private _estabelecimentoService: EstabelecimentoService,
    private _prodService: ProductsService,
    private _marketplace: MarketplaceService,
    private _servicoGiftService: ServicoGiftService,
    private _categoriaEstabelecimentoService: CategoriaEstabelecimentoService,
  ) { }

  ngOnInit(): void {
    // this.buscarBanners();
    this.search();
    this.getCategorias();
    this.buscarCategoriasEstabelecimento();
    this.buscarFiltrosAnuncio();
  }
  formatDecimal(string) {
    if (string != "") {
      return parseFloat(string).toFixed(2);
    }
  }




  formatDateBD(date) {
    if (date) {
      var dateSent = new Date(date);
      var startTime = moment(dateSent).format("YYYY/MM/DD");
      return startTime;
    }
  }

  open(content) {
    console.log("aqui")
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  search() {
    this.getProducts();
    this.buscarEstabelecimentoLista();
    this.getAnuncios();
    this.buscarServicos();
  }
  async getProducts() {
    this.loadingProdutos = true;
    await this._prodService.buscarProdutosMarketplace({ "page": this.pageProdutos, "itens": this.pageSize, "busca": this.buscar, "loja": this.buscaProduto.loja, "genero": this.buscaProduto.genero, "categoria": this.buscaProduto.categoria, "ordem": this.buscaProduto.ordem }).then(data => {
      this.loadingProdutos = false;
      var prod: any = data;
      this.products = prod.payload.produtos;
      this.totalProdutos = prod.payload.totalItens[0].totalItens;
      for (var i = 0; i < this.products.length; i++) {
        this.products[i].imagens = [];
        this.products[i].imagens.push(this.products[i].ProductsImage);

      }
    }).catch(e => { this.loadingProdutos = false; });
  }

  changePage(page) {
    this.loadingProdutos = true;
    this._prodService.buscarProdutosMarketplace({ "page": this.pageProdutos, "itens": this.pageSize, "busca": this.buscar, "loja": this.buscaProduto.loja, "genero": this.buscaProduto.genero, "categoria": this.buscaProduto.categoria, "ordem": this.buscaProduto.ordem }).then(data => {
      this.loadingProdutos = false;
      var prod: any = data;
      this.products = prod.payload.produtos;
    }).catch(e => { this.loadingProdutos = false; });
  }

  async buscarEstabelecimentoLista() {
    this.loadingLojas = true
    await this._estabelecimentoService.selecionarEstabelecimentoLista({ "busca": this.buscar, "categoria": this.categoriaSelecionada }).then(data => {
      var aux = data;
      this.estabelecimentos = aux.payload;
      this.loadingLojas = false
      for (var i = 0; i < this.estabelecimentos.length; i++) {
        if (this.estabelecimentos[i].foto) {
          this.estabelecimentoImagem.push({ "path": "https://img.pipooh.com.br/" + this.estabelecimentos[i].foto });
        }
      }
    }).catch(e => { this.loadingLojas = false });

  }

  async getAnuncios() {
    this.loadingAnuncios = true;
    await this._marketplace.getAnuncios({ "tipo": this.buscaAnuncio.tipo, "uf": this.buscaAnuncio.uf, "cidade": this.buscaAnuncio.cidade, "categoria": this.buscaAnuncio.categoria, "busca": this.buscar }).then(data => {
      this.loadingAnuncios = false;
      var aux: any = data;
      this.anuncios = aux.payload;
      this.anuncios.forEach(anuncio => {
        if (anuncio.tipo == 'Venda') {
          this.anunciosVendas.push(anuncio)
        } else {
          this.anunciosDoacao.push(anuncio);
        }
      });
    }).catch(e => { this.loadingAnuncios = false; })
  }

  getCategorias() {
    this._prodService.buscarCategorias({}).then(data => {
      var cat: any = data;
      this.categorys = cat.payload;
      if (this.route.snapshot.params['filtro']) {
        this.buscaProduto.categoria = this.route.snapshot.params['filtro'];

      }
    })

  }

  buscarCategoriasEstabelecimento() {
    this._categoriaEstabelecimentoService.selecionarCategoria({}).then(data => {
      this.categoriasEstabelecimento = data.payload;
    })
  }
  buscarFiltrosAnuncio() {
    this._marketplace.buscarFiltros({}).then(data => {
      this.cidades = data.payload.cidade;
      this.ufs = data.payload.uf;
      this.categoriasAnuncio = data.payload.categoria;
    })
  }
  buscarServicos() {
    this.loadingServicos = true;
    this._servicoGiftService.buscarServicos({ "fkEstabelecimento": 64 }).then(data => {
      this.loadingServicos = false;
      this.servicos = data.payload;
      for (var i = 0; i < this.servicos.length; i++) {
        this.servicos[i].imagem = "https://img.pipooh.com.br/imagens/servicos_estabelecimento/" + this.servicos[i].imagem;
      }
    }).catch(e => { this.loadingServicos = false; })
  }


  urlImage(image, loja) {
    if (loja == 5 || loja == 0) {
      image = this.URLNova + image;
    }
    return image;
  }
  navLink(link) {
    window.open(
      link,
      '_blank'
    );
  }

  openProduto(prod) {
    this.chosenImg = 0;
    this.product.obs = "";
    this.more = false;
    document.getElementById("openModalProduto").click();
    this.product = prod;
  }

}
