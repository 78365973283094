import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compre-pontue-regulamento',
  templateUrl: './compre-pontue-regulamento.component.html',
  styleUrls: ['./compre-pontue-regulamento.component.css']
})
export class ComprePontueRegulamentoComponent implements OnInit {

  constructor() { }
  src: any = "../../../assets/pdf/RegulamentoComprePontueFinal.pdf"
  ngOnInit(): void {
  }

}
