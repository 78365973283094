<app-header></app-header>

<div class="row justify-content-center" style="margin-bottom: 15px; margin-top: 10px;">
  <div class="col-lg-5" style="padding: 50px;">
    <h2 class="maeTitulo" style="color:#01B1AF; font-weight:700;">CHÁ DE BEBÊ</h2>
    <h2 class="maeTitulo" style="color: #F8B3CF;">{{this.infoEvento.nome}}</h2>
    <p class="bannerParagraph">Você presenteia os pais pelo site Pipooh, eles recebem os presentes conforme o bebê
      cresce, evitando que o seu presente seja perdido. Eles ainda ganham pontos e benefícios.</p>
    <div class="row">
      <div class="col-lg-3" style="margin-bottom: 10px;">
        <a class="btn btn-primary" onclick="document.getElementById('openModalSaibaMais').click();"
          style="border-radius: 25px;">Saiba mais</a>
      </div>
      <div class="col-lg-4" style="margin-bottom: 10px;">

        <a class="btn btn-primary" (click)="ancora('produtos')" style="border-radius: 25px;">Presentear agora</a>
      </div>

    </div>

  </div>
  <div class="col-lg-5" style="padding: 25px; text-align: center;">
    <img style=" max-width: 100%;max-height: 400px; min-width: 50%;" class="imagemPerfil"
      src="https://img.pipooh.com.br/{{this.infoMamae.UserFoto}}" alt="">

  </div>
</div>

<div class="row justify-content-center" style="padding: 25px; ">
  <div class="col-lg-2 col-md-6 col-sm-10 col-xs-10  "
    style="margin-bottom: 30px; margin-left: 10px; margin-right: 10px;">
    <div class="row">
      <div class="col-12 maeDados" style="padding-left: 0;padding-right: 0;">
        <!-- <img style="width: 100%;" class="imagemPerfil" src="https://pipooh.com.br/{{this.infoMamae.UserFoto}}" alt=""> -->
        <div style="padding: 15px;">

          <h2 class="maeTexto" style="margin-bottom: -10px;">
            <img src="assets/img/convidado/bebe.svg"> {{this.infoEvento.nomeFilho}}
          </h2>

          <br>
          <p style="font-size: 15px; color: #ffffff;"><img src="assets/img/convidado/calendario.svg"> Presentear até: {{
            this.infoEvento.vencimento | date: "dd-MM-yyyy" }}</p>
          <div *ngIf="this.infoMamae.chaRifa==1" style="margin-bottom: 5px;">
            <p style="color: #ffffff; margin-bottom: -25px;">
              Mamãe está organizando:
            </p>
            <h4 class="mt-4" style="color: #F8B3CF;">Rifa </h4>
            <p style="color: #ffffff; font-size: 15px; margin-bottom: -5px;">Valor do bilhete:
              R${{this.bilhetesValor}}</p>

            <div class="col-12 btn" onclick="document.getElementById('openModalChaRifa').click();"
              style="text-align: center; align-items: center; margin-bottom: -20px; border-radius: 25px; color: #01B1AF; background-color: #ffffff;">
              Ver Prêmios
            </div>


          </div>


          <div class="mt-4" *ngIf="this.infoMamae.chaPresencial==1 || this.infoMamae.chaVirtual==1">
            <h4 class="mt-4" style="color: #F8B3CF;">Dados da Festa: </h4>

            <div *ngIf="this.infoMamae.chaPresencial==1">
              <p style="color: #ffffff;">Festa Presencial Data :{{ this.infoMamae.dataPresencial | date: "dd-MM-yyyy" }}
              </p>
              <p style="color: #ffffff;">Endereço:{{this.infoMamae.enderecoPresencial}}</p>
            </div>

            <div *ngIf="this.infoMamae.chaVirtual==1">
              <p style="color: #ffffff;"> Festa Virtual Data : {{ this.infoMamae.dataVirtual | date: "dd-MM-yyyy" }}

              </p>
              <p style="color: #ffffff;">Endereço:{{this.infoMamae.linkVirtual}}</p>

            </div>

            <div *ngIf="this.infoMamae.chaCarreata==1">
              <p style="color: #ffffff;">Festa Carreata Data : {{ this.infoMamae.dataCarreata | date: "dd-MM-yyyy" }}

              </p>
              <p style="color: #ffffff;">Endereço:{{this.infoMamae.enderecoCarreata}}</p>

            </div>

            <div class="col-12 btn" onclick="document.getElementById('openModalPresenca').click();"
              style="text-align: center; align-items: center; border-radius: 25px; color: #01B1AF; background-color: #ffffff;">
              Confirme presença </div>
          </div>
        </div>
        <div style="background-color: #F8B3CF; width: 100%; border-radius: 10px;padding: 10px;">
          <p style="text-align: center;">Mensagem da Mamãe/Papai:</p>
          <p *ngIf="this.infoMamae.userApresentacao!=null" style="color: #6A6A6A;  font-style: italic;">
            {{this.infoMamae.userApresentacao}}</p>
          <p *ngIf="this.infoMamae.userApresentacao==null" style="color: #6A6A6A;  font-style: italic;">Estou muito
            feliz por vocês
            participarem do meu chá!</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12 col-md-7 p-xs-1">

    <div class="row pt-md-5">
      <div class="col-xs-12 col-md-3">
        <div class="input-group mb-3" style="border-radius: 25px;">
          <select class="form-control" [(ngModel)]="this.selectedCategory" style="width: 100%;" (change)="onSelect()"
            style="border-radius: 25px;">
            <option value="" style="border-radius: 25px;">Categorias </option>
            <option *ngFor="let cat of categorys" style="border-radius: 25px;" value="{{cat.CategoryID}}">
              {{cat.CategoriaName}}
            </option>

          </select>

        </div>
      </div>
      <div class="col-xs-12 col-md-9">
        <div class="input-group mb-3" style="border-radius: 25px;">
          <input style="border-radius: 25px;" type="text" class="form-control" (keyup.enter)="getSearch()"
            [(ngModel)]="this.search" placeholder="Buscar um produto..." aria-label="Recipient's username"
            aria-describedby="button-addon2" />
          <div class="input-group-append mr-md-5">
            <button style="border-radius: 25px;" class="btn btn-outline-secondary" (click)="getSearch()" type="button"
              id="button-addon2">
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="this.products" id="produtos">
      <div class="col-xs-12 col-md-4" *ngFor="let prod of products | slice: (page-1) * pageSize : page * pageSize">

        <div class="row" style="text-align: center;">
          <div class="col-md-12 col-5" style="margin-bottom: 10px;">
            <img (click)="openProduto(prod)" src="{{this.urlImage(prod.ProductsImage)}}" class="" alt=""
              style="max-height: 250px; max-width: 100%; border-radius: 25px;box-shadow: 0px 3px 6px #00000029;" />
          </div>

          <div class="col-md-12 col-7" style="text-align: center;">
            <div style="min-height: 70px; max-height: 90px;">
              <h6 class="" style="color:#6A6A6A;">{{prod.ProductsName}}</h6>

            </div>
            <div class="row justify-content-center">
              <div class="col-12" style="text-align: center;">
                <h4 class="mt-1" style="color: #01B1AF;">R$ {{this.formatDecimal(prod.ProductsPrice)}}</h4>

              </div>
              <div class="col-6 btn btn-primary" (click)="openProduto(prod)"
                style="text-align: center; margin-bottom: 10px; border-radius: 25px; color: white;">
                <!-- <img class="fillCart" src="/assets/img/convidado/cart.svg" /> -->
                Presentear
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row py-3" *ngIf="!this.products">
      <div class="col-xs-12 col-md-4 py-2">
        <div class="media">
          <h3>Produto não encontrado</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 justify-content-center">
        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [collectionSize]="products.length"
          [rotate]="true">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>




<!-- Modais a partir daqui -->
<ng-template #chaRifa let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="" style="padding: 8px; border: 8px solid #04cdcd;">
      <div class="" style="width: 100%; display: block; text-align: center;">
        <img width="30%" src="assets/img/convidado/rifa.png">

        <h5 class="modal-title" style="text-align: center; color: #04cdcd; font-weight: 900; font-size: 25px;">CHÁ RIFA:
          <br>
        </h5>
        <h5 class="modal-title" style="text-align: center; color: #04cdcd; font-weight: 900; font-size: 25px;">
          {{this.infoEvento.nomeFilho}}</h5>
      </div>
      <br>
      <div class="form-row"
        style="display: block; padding-left: 10px; padding-top: 20px; font-size: 14px; font-weight: 700; width: 100%; margin:auto; text-align:center">
        Valor do Bilhete: <span
          style="color: #04cdcd; font-weight: 900; padding-left: 3px; padding-right: 3px; margin:auto">R$<span
            id="ContentPlaceHolder1_phValBilhete">{{this.bilhetesValor}}</span></span>
      </div>

      <div *ngFor="let premio of this.premiosRifa"
        style="background-color: #cc61ce00; border-radius: 12px; border: 1px solid #04cdcd; margin-top: 20px; padding-bottom: 6px; width:100%">

        <div class="row">
          <div class="col-3" style="text-align: center;">
            <i class="fa fa-trophy mx-2" style="font-size: 40px;"></i>
          </div>
          <div class="col-7">
            <p style="margin-top: 5px; margin-bottom: 0px; margin-left: 10px;">
              Premio :
              <span style="margin-left: 10px;">{{premio.premio}}</span>
            </p>
            <span style="margin-left: 10px;"></span>
          </div>
        </div>


      </div>



      <div class="form-row" style="padding-top: 10px; padding-bottom: 0px; width: 100%">
        <a id="PremiosRifa" style="color: #ea85de; margin: auto; font-weight: 700;">Você presenteia a mamãe e recebe
          bilhetes em troca.</a><br>
      </div>
      <p id="rifaMsgOut" style="text-align: center; margin:auto">
        <br><a class="btn btn-outline-primary"
          href="https://www.youtube.com/watch?v=X9FNwLGnAm4&amp;ab_channel=Pipooh-MeuCh%C3%A1Fralda" target="_blank">
          Ainda tem dúvidas? Clique aqui.
        </a>
      </p>
    </div>

  </div>
</ng-template>

<button id="openModalChaRifa" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(chaRifa)"
  data-target="#openModalChaRifa">Launch demo modal</button>

<ng-template #sucessoProduto let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="text-align: center; padding:10px;">
      <h2 style="color: #04cdcd;">Produto adicionado com sucesso!</h2>

      <div class="row" *ngIf="this.carrinho">
        <div class="" style="width: 100%;">

          <table class="" style="width: 100%;">
            <thead>
              <tr>
                <th scope="col">Imagem</th>
                <th scope="col">Tipo</th>
                <th scope="col">Qtd</th>
                <th scope="col">Preço</th>
                <th scope="col">Total</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let car of carrinho">
                <td width="20%"><img src="{{this.urlImage(car.ProductsImage)}}" alt="" width="100%"
                    style="border-radius: 20px;" /></td>
                <td>{{car.CategoriaName}}</td>
                <td>{{car.quantidade}}</td>
                <td>{{this.formatDecimal(car.ProductsPrice)}}</td>
                <td>{{this.formatDecimal(car.ProductsPrice * car.quantidade)}}</td>
                <td (click)="removeCarrinho(car)"><i class="fas fa-times" style="color:#842029;cursor: pointer;"></i>
                </td>
              </tr>
              <tr style="height: 50px;">
                <td colspan="3"></td>
                <td><strong>Total</strong></td>
                <td>R$ {{this.formatDecimal(this.total)}}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" style="margin-bottom: 20px;">
        <div class="col-6">
          <a class="close btn btn-carrinho" aria-label="Close" (click)="modal.dismiss('Cross click')"
            style="font-size: 13px;">
            Continue Comprando </a>
        </div>
        <div class="col-6">
          <a *ngIf="this.carrinho.length!=0" href="/carrinho" (click)="modal.dismiss('Cross click')"
            style="font-size: 13px;" class="btn btn-primary">Finalizar Carrinho</a>

        </div>
      </div>
    </div>

  </div>
</ng-template>

<button id="openModalSucessoProduto" class="btn btn-lg btn-outline-primary" [hidden]="true"
  (click)="open(sucessoProduto)" data-target="#openModalSucessoProduto">Launch demo modal</button>




<ng-template #modalProduto let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body info-produto">
    <div class="container-fluid ">
      <div class="row">
        <div class="col-md-6">
          <div *ngFor="let img of product.imagens ; let i=index;let firstItem = first;" class="tab-pane active"
            id="imagem{{i}}" aria-expanded="true">
            <img [hidden]="this.chosenImg != i" src="{{this.urlImage(img)}}" width="100%"
              style="border-radius: 25px;" />
          </div>

          <ul *ngIf="product"
            class="nav nav-pills nav-pills--brand m-nav-pills--align-right m-nav-pills--btn-pill m-nav-pills--btn-sm"
            role="tablist">
          </ul>
        </div>
        <div class="col-md-6">
          <h5>{{this.product.ProductsName}}</h5>
          <div *ngIf="this.more">
            <div [innerHTML]="this.product.ProductsDescription">
            </div>
          </div>

          <span *ngIf="!this.more" (click)="this.more = true" class="more">...Ler mais</span>
          <h4 class="mt-3" style="color: #01b1af;">R$ {{this.formatDecimal(this.product.ProductsPrice)}}</h4>
          <br>
          <div class="row" style="margin-bottom: 20px;">
            <div class="col-12 justify-content-center" style="text-align: center;">
              <div class="pro-qty" style="border-radius: 25px;">
                <span (click)="changeQtd('down')" class="dec qtybtn">-</span>
                <input name="lblQuantidade" [(ngModel)]="quantidade" type="text">
                <span (click)="changeQtd('up')" class="inc qtybtn">+</span>
              </div>
              <a (click)="addCarrinho(this.product); modal.dismiss('Cross click');"
                onclick="document.getElementById('openModalSucessoProduto').click();" class="btn btn-primary">Adicionar
                ao
                carrinho</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<button id="openModalProduto" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalProduto)"
  data-target="#openModalProduto">Launch demo modal</button>

<ng-template #modalSaibaMais let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
      style="background-color: #01B1AF; border-radius: 50px;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body info-produto">
    <div class="row" style="padding: 20px; padding-right: 30px;">
      <div class="col-12">
        <h3 style="text-align: center;color: #F8B3CF;">Como o meu presente chega na mamãe?</h3>
      </div>
      <div class="col-12">
        <ol>
          <li>O convidado presenteia a mamãe, seu presente é convertido em créditos.</li>
          <li>A mamãe recebe os créditos para resgatar presentes na lojinha Pipooh ou em parceiros quando quiser, sem
            ocupar espaço em casa.</li>
          <li>Assim que a mamãe sentir necessidade, ela seleciona os presentes e faz o resgate.</li>
          <li>Ela recebe em casa ou vai a uma loja física.</li>
          <li>Com o seu presente ela ainda acumula pontos e troca por produtos.</li>
        </ol>
      </div>
    </div>
  </div>
</ng-template>

<button id="openModalSaibaMais" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalSaibaMais)"
  data-target="#openSaibaMais">Launch demo modal</button>



<ng-template #modalPresenca let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="" style="padding: 8px; ">
      <div class="" style="width: 100%; display: block; text-align: center;">

        <h5 class="modal-title" style="text-align: center; color: #04cdcd; font-weight: 900; font-size: 25px;">Confirme
          a sua presença no evento
          <br>
        </h5>
      </div>
      <br>


      <div *ngIf="this.infoMamae.chaPresencial==1"
        style="background-color: #cc61ce00; border-radius: 12px; border: 1px solid #04cdcd; margin-top: 20px; padding-bottom: 6px; width:100%">
        <div class="row" style="padding: 20px;">
          <div class="col-12">
            <div>
              <p style="color: #01b1af;">Festa Presencial Data :{{ this.infoMamae.dataPresencial | date: "dd-MM-yyyy" }}
              </p>
              <p style="color: #01b1af;">Endereço:{{this.infoMamae.enderecoPresencial}}</p>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="this.infoMamae.chaVirtual==1"
        style="background-color: #cc61ce00; border-radius: 12px; border: 1px solid #04cdcd; margin-top: 20px; padding-bottom: 6px; width:100%">
        <div class="row" style="padding: 20px;">
          <div class="col-12">
            <div>
              <p style="color: #01b1af;"> Festa Virtual Data : {{ this.infoMamae.dataVirtual | date: "dd-MM-yyyy" }}

              </p>
              <p style="color: #01b1af;">Endereço:{{this.infoMamae.linkVirtual}}</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.infoMamae.chaCarreata==1"
        style="background-color: #cc61ce00; border-radius: 12px; border: 1px solid #04cdcd; margin-top: 20px; padding-bottom: 6px; width:100%">
        <div class="row" style="padding: 20px;">
          <div class="col-12">
            <div>
              <p style="color: #01b1af;">Festa Carreata Data : {{ this.infoMamae.dataCarreata | date: "dd-MM-yyyy" }}

              </p>
              <p style="color: #01b1af;">Endereço:{{this.infoMamae.enderecoCarreata}}</p>
            </div>
          </div>
        </div>
      </div>
      <p style="text-align: center;">Pedimos que confirme presença apenas uma vez.</p>
      <div class="row justify-content-center" style="margin-bottom: 10px;">
        <div class="col-lg-7">
          <input type="text" class="input-group"
            style="padding: 5px; border-radius: 20px; border: 1px solid #04cdcd; margin-bottom: 15px;"
            [(ngModel)]="presencaConfirmacao" placeholder="Insira seu nome">
          <input type="email" class="input-group" style="padding: 5px; border-radius: 20px; border: 1px solid #04cdcd;"
            [(ngModel)]="presencaEmail" placeholder="Insira seu email (opcional)">

        </div>
      </div>

      <div style="text-align: center;">
        <div class="btn" style="background-color: #F8B3CF; border-radius: 25px; color: white; text-align: center;"
          (click)="confirmarPresenca(); modal.dismiss('Cross click')">Confirme sua presença!</div>

      </div>
    </div>

  </div>
</ng-template>

<button id="openModalPresenca" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalPresenca)"
  data-target="#openPresenca">Launch demo modal</button>
  <app-footer></app-footer>