import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventoEstabelecimentoService {
  private URLAspNet = `${environmentAspNet.api}`;
  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any>
    {

        return lastValueFrom(this.http.post(url, formData,{responseType: 'json'})).then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else
            {
                return aux;
            }
        });

    }

  buscarEvento(formData) {
    return this.postData(`${this.URLAspNet}eventoEstabelecimento/web/select/evento`, formData);
  }
  buscarEnqueteOpcao(formData) {
    return this.postData(`${this.URLAspNet}eventoEstabelecimento/web/select/enquete/opcao`, formData);
  }
  buscarEventos(formData) {
    return this.postData(`${this.URLAspNet}eventoEstabelecimento/web/select/lista_evento`, formData);
  }
  buscarSessoesTema(formData) {
    return this.postData(`${this.URLAspNet}eventoEstabelecimento/web/select/sessoes/tema`, formData);
  }
  buscarSessoesDataTema(formData) {
    return this.postData(`${this.URLAspNet}eventoEstabelecimento/web/select/sessoes/data/tema`, formData);
  }
  buscarNPS(formData) {
    return this.postData(`${this.URLAspNet}eventoEstabelecimento/web/select/nps`, formData);
  }
  insertNPS(formData) {
    return this.postData(`${this.URLAspNet}eventoEstabelecimento/web/insert/nps`, formData);
  }

}
