import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EstabelecimentoService } from 'src/app/_services/estabelecimento.service';
import { ProductsService } from 'src/app/_services/products.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { cpf } from 'cpf-cnpj-validator';
import * as moment from 'moment';
@Component({
  selector: 'app-cadastro-livelo',
  templateUrl: './cadastro-livelo.component.html',
  styleUrls: ['./cadastro-livelo.component.css']
})
export class CadastroLiveloComponent implements OnInit {

  public URLNOVA = "https://img.pipooh.com.br/"
  // Estabelecimento
  estabelecimento: any = [];
  estabelecimentos: any = [];
  estabelecimentoDetalhe: any = [];
  estabelecimentoImagem: any = [];
  //Filtro
  nomeEstabelecimento: any;
  busca: any;
  //Paginação
  page = 1;
  pageSize = 6;
  collectionSize = [];
  //
  loading: any = 0;
  loadingBusca: any = 0;
  closeResult = '';
  products: any = [];
  categorys: any = "";
  category: any = "";
  description: any = "";
  search: any = "";
  product: any = [];
  more: boolean = false;
  imagens: any;
  chosenImg: any;
  selectedCategory: any = "";
  //pedido
  valor: any;
  pedido: any = [];
  //Formularios
  formCadastrado: FormGroup;
  formCadastrar: FormGroup;
  submitted: any = false;
  //
  semanas: any = [];
  semana: any;


  constructor(private _estabelecimentoService: EstabelecimentoService,
    private modalService: NgbModal,
    private _prodService: ProductsService,
    private _usuarioService: UsuarioService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formCadastrar = this.formBuilder.group({
      nome: ['', [Validators.required]],
      sexo: ['mamãe', [Validators.required]],
      email: ['', [Validators.required, , Validators.email]],
      cpf: ['', [Validators.required, Validators.minLength(11)]],
      celular: ['', [Validators.required, Validators.minLength(11)]],
      senha: ['', [Validators.required,]],
      senhaR: ['', [Validators.required,]],
      tipo: ['gestante', [Validators.required,]],
      termos: ['', [Validators.required,]],
      politica: ['', [Validators.required,]],
      semana: ['',],
      ultimaMenstruacao: ['',],
      dataNascimentoCrianca: ['',],
    });
    for (var i = 42; i >= 1; i--) {
      if (i != 0) {
        this.semanas[i] = i;
      }
    }
    this.semanas.shift();
    this.buscarEstabelecimentoLista("", "");
    this.getCategorias();
    this.imagens = JSON.parse('{"payload":[{"id":"1"}, {"id":"2"}, {"id":"3"}]}');
  }


  formatDateBD(date) {
    if (date) {
      var dateSent = new Date(date);
      var startTime = moment(dateSent).format("YYYY/MM/DD");
      return startTime;
    }
  }

  onSubmitFormCadastrar() {
    this.submitted = true;
    this.loading = true;
    if (!this.formCadastrar.valid) {
      return false;
    } else {
      this._usuarioService.selecionarEmail({ "email": this.formCadastrar.value.email }).then(data => {
        if (data.payload.length > 0) {
          this.loading = false;
          this.toastr.error("E-mail já cadastrado na Pipooh", "E-mail inválido");
        } else {
          if(this.formCadastrar.value.nome.indexOf(' ') <= 0){
            this.toastr.error('Informe o nome completo', 'Falha no cadastro'); this.loading = false; return false;
          };
          if (cpf.isValid(this.formCadastrar.value.cpf) == false) { this.toastr.error('CPF inválido', 'Falha no cadastro'); this.loading = false; return false; }
          if (this.formCadastrar.value.senha != this.formCadastrar.value.senhaR) { this.toastr.error('As senhas não coincidem', 'Falha no cadastro'); this.loading = false; return false; }
          if (this.formCadastrar.value.tipo == "gestante") {
            if (this.formCadastrar.value.semana == undefined || this.formCadastrar.value.semana == "") {
              this.toastr.error('Semana é obrigátoria', 'Falha no cadastro');
              this.loading = false;
              return false;
            }
            else {
              var date = new Date;
              var dias = parseInt(this.formCadastrar.value.semana) * 7;
              date.setDate(date.getDate() - dias);
              this.formCadastrar.value.ultimaMenstruacao = this.formatDateBD(date);
            }
            if (this.formCadastrar.value.ultimaMenstruacao == undefined || this.formCadastrar.value.ultimaMenstruacao == "") { this.toastr.error('Data da menstruação é obrigátoria', 'Falha no cadastro'); this.loading = false; return false; }
          } else if (this.formCadastrar.value.tipo == "criancaNasceu") {
            if (this.formCadastrar.value.dataNascimentoCrianca == undefined || this.formCadastrar.value.dataNascimentoCrianca == "") {
              this.toastr.error('Data de nascimento é obrigátoria', 'Falha no cadastro');
              this.loading = false;
              return false;
            }
          }
          this._usuarioService.userInsertLivelo(this.formCadastrar.value).then(data => {
            if (data.success) {
              document.getElementById("openModalInfoConta").click();
              document.getElementById("openModalCadastrar").click();
              this.loading = false;
            } else {
              console.log("form", this.formCadastrar)
              this.loading = false;
            }
          }).catch(e => {
            this.loading = false;
          })
        }
      });
    }
  }
  login(){
    window.location.href = "https://app.pipooh.com.br/";
  }
  buscarEstabelecimentoLista(busca, categoria) {
    this.loadingBusca = 1;
    this._estabelecimentoService.buscarEstabelecimentos({ "busca": busca, "categoria": categoria }).then(data => {
      var aux = data;
      this.estabelecimentos = aux.payload;
      this.loadingBusca = 0;
      for (var i = 0; i < this.estabelecimentos.length; i++) {
        if (this.estabelecimentos[i].foto) {
          this.estabelecimentoImagem.push({ "path": "https://img.pipooh.com.br/" + this.estabelecimentos[i].foto });
        }
      }
    });
    this.nomeEstabelecimento = null;

  }

  changeImg(i) {
    this.chosenImg = i;
  }

  formatDecimal(string) {
    if (string != "") {
      return parseFloat(string).toFixed(2);
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title,', size: 'lg', windowClass: 'modal', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getSearch() {
    this.products = [];
    this.category = "Descricao";
    this.description = this.search;
    if (this.search == "") { this.description = ''; this.category = ''; }
    this.getProducts(this.category, this.description);
  }
  getProducts(productFilter, productDescription) {
    this._prodService.getProductsListAll({ "pProductsFiltro": productFilter, "pProductsDescricao": productDescription, "pFranquia": "1" }).then(data => {
      var prod: any = data;
      this.products = prod.payload;
      this._prodService.getProductsImagem({ "fkProduto": "" }).then(data2 => {
        var imgs: any = data2;
        this.imagens = imgs.payload;
        for (var i = 0; i < this.products.length; i++) {
          this.products[i].imagens = [];
          this.products[i].imagens.push(this.products[i].ProductsImage);
          for (var h = 0; h < this.imagens.length; h++) {
            if (this.products[i].ProductsID == this.imagens[h].fkProduto) {
              this.products[i].imagens.push(this.imagens[h].produtoImagem);
            }
          }
        }
      })
    })
  }
  getCategorias() {
    this._prodService.getCategorias({}).then(data => {

      var cat: any = data;
      this.categorys = cat.payload;
      if (this.route.snapshot.params['filtro']) {
        this.selectedCategory = this.route.snapshot.params['filtro'];
      }

      this.onSelect();
    })

  }

  onSelect() {

    this.products = [];
    this.category = "Categoria";
    this.description = this.selectedCategory;
    if (this.selectedCategory == "") { this.description = ''; this.category = ''; }

    this.getProducts(this.category, this.description);
  }

  openProduto(prod) {
    this.chosenImg = 0;
    this.product.obs = "";
    this.more = false;
    document.getElementById("openModalProduto").click();
    this.product = prod;
  }
  abrirInfoCompra(content) {
    if (this.valor == "" || this.valor == null || this.valor < 0) {
      this.toastr.error("Valor inválido");
      return;
    }
    this.modalService.open(content, { centered: true });
  }

  urlImage(image) {
    return this.URLNOVA + image;
  }
  urlImageLoja(image) {
    return image;
  }

}
