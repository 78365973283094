<p class="headerTitulo">Confira agora o <a href="https://img.pipooh.com.br/imagens/pdf/RegulamentoGift.pdf"
        target="_blank">regulamento</a> </p>

<img src="../../../assets/img/banners/CadastroLivelo.jpg" class="d-none d-lg-block" width="100%" alt="">
<img src="../../../assets/img/banners/CadastroLiveloMobile.jpg" class="d-lg-none" width="100%" alt="">
<div class="container">

    <div class="row justify-content-center">
        <!-- <div class="col-12"> -->

        <br>
        <!-- <p style="text-align: center;margin-top: 5px;">Até 5.000 <strong>pontos</strong> Livelo </p> -->
        <p style="text-align: center;margin-top: 15px;"><button class="btn btn-primary hidden-xs" style="width: 30%;"
                (click)="open(modalCadastrar)">Cadastrar</button>
            <button class="btn btn-primary d-lg-none" style="width: 100%;"
                (click)="open(modalCadastrar)">Cadastrar</button>
        </p>


    </div>

    <div class="row justify-content-center" *ngIf="estabelecimentoImagem.length > 0">
        <div class="col-xs-12 col-md-10 p-xs-1" style="margin-top: 15px">
            <h3 style=" color:#01b1af;  text-align: center;">Onde usar meus créditos:</h3>
            <carousel [images]="estabelecimentoImagem" [borderRadius]="100" [cellWidth]="100" [height]="100">
            </carousel>
            <br>
        </div>
    </div>
    <br>
    <hr>
    <br>

    <div class="row justify-content-center" style="margin-top: 15px">

        <div class="col-xs-12 col-md-10 p-xs-1">
            <h3 style=" color:#01b1af;  text-align: center;">O que comprar com os créditos:</h3>
            <div class="row pt-md-5 ">
                <div class="col-xs-12 col-md-3">
                    <div class="input-group mb-3" style="border-radius: 25px;">
                        <select class="form-control" [(ngModel)]="this.selectedCategory" style="width: 100%;"
                            (change)="onSelect()" style="border-radius: 25px;">
                            <option value="" style="border-radius: 25px;">Todos</option>
                            <option *ngFor="let cat of categorys" style="border-radius: 25px;"
                                value="{{cat.CategoryID}}">
                                {{cat.CategoriaName}}
                            </option>

                        </select>
                    </div>
                </div>
                <div class="col-xs-12 col-md-9">
                    <div class="input-group mb-3" style="border-radius: 25px;">
                        <input style="border-radius: 25px;" type="text" class="form-control" (keyup.enter)="getSearch()"
                            [(ngModel)]="this.search" placeholder="Buscar um produto..."
                            aria-label="Recipient's username" aria-describedby="button-addon2" />
                        <div class="input-group-append mr-md-5">
                            <button style="border-radius: 25px;" class="btn btn-outline-secondary" (click)="getSearch()"
                                type="button" id="button-addon2">
                                Buscar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="this.products">
                <div class="col-xs-12 col-md-4"
                    *ngFor="let prod of products | slice: (page-1) * pageSize : page * pageSize">

                    <div class="row" style="text-align: center;">
                        <div class="col-md-12 col-5" style="margin-bottom: 10px;">
                            <img (click)="openProduto(prod)" *ngIf="!prod.fkProdutos_LojaIntegrada"
                                src="{{ this.urlImage(prod.ProductsImage) }}" class="" alt="" style="
                    max-height: 150px;
                    max-width: 100%;
                    border-radius: 25px;
                    box-shadow: 0px 3px 6px #00000029;
                  " />
                            <img (click)="openProduto(prod)" *ngIf="prod.fkProdutos_LojaIntegrada"
                                src="{{ this.urlImageLoja(prod.ProductsImage) }}" class="" alt="" style="
                    max-height: 150px;
                    max-width: 100%;
                    border-radius: 25px;
                    box-shadow: 0px 3px 6px #00000029;
                  " />
                            <!-- <img (click)="openProduto(prod)" src="{{this.urlImage(prod.ProductsImage)}}" class="" alt=""
                                style="max-height: 250px; max-width: 100%; border-radius: 25px;box-shadow: 0px 3px 6px #00000029;" /> -->
                        </div>

                        <div class="col-md-12 col-7" style="text-align: center;">
                            <div style="min-height: 30px; max-height: 30px;">
                                <h6 class="" style="color:#6A6A6A;">{{prod.ProductsName}}</h6>

                            </div>
                            <div class="row justify-content-center">
                                <div class="col-12" style="text-align: center;">
                                    <h6 class="mt-1" *ngIf="prod.CategoryID == 25"
                                        style="color: red; text-decoration: line-through;">R$
                                        {{this.formatDecimal(prod.precoAnterior)}}
                                    </h6>
                                    <h4 class="mt-1" style="color: #01B1AF;">R$
                                        {{this.formatDecimal(prod.ProductsPrice)}}
                                    </h4>

                                </div>
                                <div class="col-6 btn btn-primary" (click)="openProduto(prod)">
                                    Visualizar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row py-3" *ngIf="!this.products">
                <div class="col-xs-12 col-md-4 py-2">
                    <div class="media">
                        <h3>Produto não encontrado</h3>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12 justify-content-center">
                    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                        [collectionSize]="products.length" [rotate]="true">
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </div>


</div>

<!-- Modais a partir daqui -->

<ng-template #modalProduto let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="container-fluid ">
            <div class="row">
                <div class="col-md-6">
                    <div *ngFor="
                        let img of product.imagens;
                        let i = index;
                        let firstItem = first
                      " class="tab-pane active" id="imagem{{ i }}" aria-expanded="true">
                        <img *ngIf="!product.fkProdutos_LojaIntegrada" [hidden]="this.chosenImg != i"
                            src="{{ this.urlImage(img) }}" width="100%" style="border-radius: 25px" />
                        <img *ngIf="product.fkProdutos_LojaIntegrada" [hidden]="this.chosenImg != i"
                            src="{{ this.urlImageLoja(img) }}" width="100%" style="border-radius: 25px" />
                    </div>

                    <ul *ngIf="product"
                        class="nav nav-pills nav-pills--brand m-nav-pills--align-right m-nav-pills--btn-pill m-nav-pills--btn-sm"
                        role="tablist">
                        <li *ngFor="let prod of product.imagens ; let i=index;let firstItem = first;"
                            class="nav-item m-tabs__item">
                            <a class="nav-link m-tabs__link" [ngClass]="{ active: firstItem }" data-toggle="tab"
                                (click)="changeImg(i)" role="tab">{{i+1}}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <h5>{{this.product.ProductsName}}</h5>
                    <div *ngIf="this.more">
                        <div [innerHTML]="this.product.ProductsDescription">
                        </div>
                    </div>

                    <span *ngIf="!this.more" (click)="this.more = true" class="more">...Ler mais</span>
                    <h4 class="mt-3" style="color: #01b1af;">R$ {{this.formatDecimal(this.product.ProductsPrice)}}</h4>
                    <br>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<button id="openModalProduto" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalProduto)"
    data-target="#openModalProduto">Launch demo modal</button>

<ng-template #modalInfoConta let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="row justify-content-center" style="text-align: center; padding: 10px;">
            <h3>Parabéns, cadastro realizado com sucesso!</h3>
            <p>Faça login agora e aproveite tudo que sua conta Pipooh oferece.</p>
            <div class="col-lg-6">
                <button class="btn btn-primary" (click)="login()"
                    style="width: 100%;">Fazer login</button>
            </div>
            <br>
        </div>
    </div>
</ng-template>

<button id="openModalInfoConta" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalInfoConta)"
    data-target="#modalInfoConta">Launch demo modal</button>

<ng-template #modalCadastrar let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="row" style=" padding: 10px;">
            <form (ngSubmit)="onSubmitFormCadastrar()" [formGroup]="formCadastrar">
                    <select class="form-control" formControlName="sexo" id="sexo">
                        <option value="mamãe">Mamãe</option>
                        <option value="papai">Papai</option>
                    </select>
                    <div class="error"
                    *ngIf="formCadastrar.get('sexo')?.errors  && (formCadastrar.get('sexo')?.touched  || submitted)">
                    Sexo obrigatório
                </div>
                    <br>

                <input type="text" class="form-control" placeholder="Nome  Completo*" formControlName="nome" id="nome">
                <div class="error"
                    *ngIf="formCadastrar.get('nome')?.errors  && (formCadastrar.get('nome')?.touched  || submitted)">
                    Nome obrigatório
                </div>
                <br>
                <input type="text" class="form-control" placeholder="E-mail*" formControlName="email" id="email">
                <div class="error"
                    *ngIf="formCadastrar.get('email').errors?.required  && (formCadastrar.get('email')?.touched  || submitted)">
                    E-mail obrigatório
                </div>
                <div class="error"
                    *ngIf="formCadastrar.get('email').errors?.email && formCadastrar.get('email')?.touched">
                    E-mail inválido
                </div>
                <br>
                <input type="text" mask="000.000.000-00" class="form-control" placeholder="CPF *" formControlName="cpf"
                    id="cpf">
                <div class="error"
                    *ngIf="(formCadastrar.get('cpf')?.errors && !formCadastrar.get('cpf').errors?.minlength) && submitted">
                    CPF obrigatório
                </div>
                <div class="error"
                    *ngIf="formCadastrar.get('cpf').errors?.minlength && (formCadastrar.get('cpf')?.touched  || submitted)">
                    CPF precisa conter 11 dígitos
                </div>
                <br>
                <input type="text" class="form-control" placeholder="Celular*" mask="(00)00000-0000"
                    formControlName="celular" id="celular">
                <div class="error"
                    *ngIf="(formCadastrar.get('celular')?.errors && !formCadastrar.get('celular')?.errors?.minlength  )  &&  submitted">
                    Celular obrigatório
                </div>
                <div class="error"
                    *ngIf="formCadastrar.get('celular')?.errors?.minlength  && (formCadastrar.get('celular')?.touched || submitted)">
                    Celular precisa conter 11 dígitos
                </div>
                <br>
                <input type="password" class="form-control" placeholder="Senha*" formControlName="senha" id="senha">
                <div class="error"
                    *ngIf="formCadastrar.get('senha')?.errors  && (formCadastrar.get('senha')?.touched || submitted)">
                    Senha obrigatório
                </div>
                <br>
                <input type="password" class="form-control" formControlName="senhaR" placeholder="Confirmar senha*"
                    id="senhaR">
                <div class="error"
                    *ngIf="formCadastrar.get('senhaR')?.errors  && (formCadastrar.get('senhaR')?.touched || submitted)">
                    Confirmar Senha obrigatório
                </div>
                <br>
                <mat-radio-group aria-label="Select an option" formControlName="tipo" id="tipo">
                    <mat-radio-button value="gestante">Gestante &nbsp;</mat-radio-button>
                    <mat-radio-button value="criancaNasceu">Criança já nasceu</mat-radio-button>
                </mat-radio-group>
                <div class="error"
                    *ngIf="formCadastrar.get('tipo')?.errors  && (formCadastrar.get('tipo')?.touched  || submitted)">
                    Tipo obrigatório
                </div>
                <p style="margin-bottom:0px;" *ngIf="formCadastrar.value.tipo == 'gestante'"><strong>
                        Quantas semanas está:
                    </strong>
                </p>
                <div class="" *ngIf="formCadastrar.value.tipo == 'gestante'">
                    <select class="form-control" formControlName="semana" id="semana">
                        <option>Selecione a semana</option>
                        <option *ngFor="let sem of semanas" value="{{sem}}">{{sem}}</option>
                    </select>
                </div>
                <p style="margin-bottom:0px;" *ngIf="formCadastrar.value.tipo == 'criancaNasceu'">Data de
                    nascimento filho mais novo:</p>
                <div class="form-group m-form__group" *ngIf="formCadastrar.value.tipo == 'criancaNasceu'">
                    <input class="form-control m-input" type="date" placeholder="Data de nascimento"
                        formControlName="dataNascimentoCrianca" id="dataNascimentoCrianca" />
                </div>
                <br>
                <br>
                <label class="m-checkbox m-checkbox--focus">
                    <input type="checkbox" formControlName="termos" id="termos" /> Eu concordo com os
                    <a href="https://img.pipooh.com.br/imagens/pdf/5e04cf7bbfb6de5a8d3255bc94f2885c.pdf" target="_blank"
                        class="m-link m-link--focus">
                        Termos de uso e condições.
                    </a>
                    <span></span>
                </label>
                <div class="error" *ngIf="formCadastrar.get('termos')?.errors  && submitted">
                    Termos de uso obrigatório
                </div>
                <br>
                <label class="m-checkbox m-checkbox--focus">
                    <input type="checkbox" formControlName="politica" id="politica" /> Eu
                    concordo com as
                    <a href="https://img.pipooh.com.br/imagens/pdf/da192f01bd138c4df9338dd70b02f1bf.pdf" target="_blank"
                        class="m-link m-link--focus">
                        Política de privacidade.
                    </a>
                    <span></span>
                </label>
                <div class="error" *ngIf="formCadastrar.get('politica')?.errors  &&  submitted">
                    Política de privacidade obrigatório
                </div>
                <div class="cupom">
                    <p>Cupom</p>
                    <h3>LIVELO</h3>
                </div>
                <div style="text-align: center;">
                    <button class="btn btn-primary" type="submit" [disabled]="loading"
                        [ngClass]="{'m-loader m-loader--right m-loader--light': loading }">
                        Cadastrar
                    </button>
                </div>

            </form>
            <br>
        </div>
    </div>
</ng-template>

<button id="openModalCadastrar" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalCadastrar)"
    data-target="#modalCadastrar">Launch demo modal</button>