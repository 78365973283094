import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CarrinhoService } from '../_services/carrinho.service';
import { environment } from './../../environments/environment.prod';
import { NgForm } from '@angular/forms';
import { ConvidadoService } from '../_services/convidado.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';




@Component({
  selector: 'app-carrinho',
  templateUrl: './carrinho.component.html',
  styleUrls: ['./carrinho.component.css']
})
export class CarrinhoComponent implements OnInit {

  public URL = "https://img.pipooh.com.br/";

  items = this._carrinho.getItems();
  model: any;
  infoMamae: any = [];
  pedidoParcialSemFrete: any;
  closeResult = '';
  Frete: any;
  Total: any;
  pedidoRetorno: any;
  pedidoValor: any;
  carrinhoTotal: any;
  valorTotal: any = 0;
  infoEvento: any = [];
  base64textString: any;
  video: any;
  loading = false;
  bilhetes: any = 0;
  valorRestante: any;
  @Output() public onUploadFinished = new EventEmitter();
  constructor(
    private _carrinho: CarrinhoService,
    private _convidado: ConvidadoService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.items = this._carrinho.getLocalCarrinho();

    this.getInfoUser();
    this.getInfoEvento();


  }
  getPedidoSemFrete() {
    var valor = this.items;
    if (valor.length == 0) {
      window.location.href = "/evento-pipooh?id=" + this.infoMamae.UserID + "&evento=" + localStorage.getItem('pipoohEvento');
    }
    this.pedidoParcialSemFrete = this.formatDecimal(valor.map(val => val.ProductsPrice).reduce((acc, val) => val + acc));
  }

  async getInfoUser() {
    let mamae = localStorage.getItem('pipoohMamae');
    await this._convidado.getMamae({ "UserID": mamae }).then(data => {
      this.infoMamae = data;
      this.infoMamae = this.infoMamae.payload[0];

      this.Frete = this.infoMamae.UserFrete;
      this.Frete = this.infoMamae.UserFrete;

      if (localStorage.getItem('frete') != null && localStorage.getItem('frete') != undefined) {
        this.Frete = localStorage.getItem('frete');

      }


      this.getPedidoSemFrete();
      this.getTotal();

      var valorFrete = Number(this.Frete);
      var valorPedido = Number(this.valorTotal);
      this.Total = valorPedido + valorFrete;


    });


  }
  getInfoEvento() {
    let mamae = localStorage.getItem('pipoohMamae');
    let evento = localStorage.getItem('pipoohEvento');

    this._convidado.getEventoPorID({ "UserID": mamae, eventoID: evento }).then(data => {
      this.infoEvento = data;
      this.infoEvento = this.infoEvento.payload[0];
      console.log("infoEvento", this.infoEvento)
      this.Frete = this.infoEvento.frete;
      this.Frete = this.infoEvento.frete;
      if(this.infoEvento.rifaAtiva == 1){
        if(this.valorTotal > this.infoEvento.valorBilhete){
          this.bilhetes = Math.floor(this.valorTotal / this.infoEvento.valorBilhete)
        this.valorRestante = this.valorTotal -  (this.infoEvento.valorBilhete * this.bilhetes);
        this.valorRestante =  this.infoEvento.valorBilhete - this.valorRestante;

        }
      }
      
      if (localStorage.getItem('frete') != null && localStorage.getItem('frete') != undefined) {
        this.Frete = localStorage.getItem('frete');

      }


      this.getPedidoSemFrete();
      this.getTotal();

      var valorFrete = Number(this.Frete);
      var valorPedido = Number(this.valorTotal);
      this.Total = valorPedido + valorFrete;
      console.log(this.infoEvento);

    });

  }
  getTotal() {
    this.valorTotal = 0;
    this.carrinhoTotal = JSON.parse(localStorage.getItem('carrinho'));
    var car = this.carrinhoTotal;

    this.carrinhoTotal.forEach((elemento) => {
      this.valorTotal = this.valorTotal + (parseFloat(elemento["ProductsPrice"]) * parseFloat(elemento["quantidade"]));

    });


  }

  urlImage(image) {
    return this.URL + image;
  }
  formatDecimal(string) {
    if (string != "") {
      return parseFloat(string).toFixed(2);
    }
  }
  removeCarrinho(item) {

    this._carrinho.removeCarrinho(item);
    this.items = this._carrinho.getLocalCarrinho();
    this.getPedidoSemFrete();
    this.getTotal();

    var valorFrete = Number(this.Frete);
    var valorPedido = Number(this.valorTotal);
    this.Total = valorPedido + valorFrete;
    this.getTotal();

  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title,', size: 'md', windowClass: 'modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSubmit(myForm: NgForm) {
    this.loading = true;
    this.pedidoRetorno = localStorage.getItem('pedidoAnt');
    this.pedidoValor = this._convidado.pedidoConvidadoValor({ 'token': this.pedidoRetorno }).then(data => {
      if (data.payload[0].PedidoValor == 0 || data.payload[0].PedidoValor == "0") {
        localStorage.removeItem('pedidoAnt');
        this.pedidoRetorno = "0";

      }

    });
    if (this.pedidoRetorno == undefined || this.pedidoRetorno == null) {
      localStorage.removeItem('pedidoAnt');
      this.pedidoRetorno = "0";

    }

    if (myForm.valid) {
      myForm.value.UserID = localStorage.getItem('pipoohMamae');
      myForm.value.CompradorFrete = this.Frete;
      myForm.value.eventoID = localStorage.getItem('pipoohEvento');
      var nome = myForm.value.CompradorNome.split(" ");
      if(nome.length == 1){
        this.toastr.error("Informe o nome completo");this.loading = false; return
      }


      this._convidado.pedidoConvidadoInsert({ 'ConvidadoDados': myForm.value, 'PedidoAntigo': this.pedidoRetorno, 'PedidoDados': this.items, "video": this.video }).then(data => {
        this.pedidoRetorno = data;

        this.pedidoRetorno = this.pedidoRetorno.payload[0];
        localStorage.setItem('pedidoAnt', this.pedidoRetorno["pTokenPedido"]);
        localStorage.setItem('rifaPedido', this.pedidoRetorno["pTokenPedido"]);
        localStorage.setItem('revelacaoID', this.infoEvento.fkRevelacao);
        localStorage.setItem('eventoRevelacao', this.infoEvento.fkEvento);
        localStorage.setItem('gemeos', this.infoEvento.gemeos);




        if (this.infoEvento.rifaAtiva == 1 && this.infoEvento.tipo != 'cha-revelacao') {
          window.location.href = "/carrinho-rifa";

        } else if (this.infoEvento.tipo == 'cha-revelacao' && this.infoEvento.statusRevelacao == true) {
          window.location.href = "/carrinho-revelacao";

        } else {
          window.location.href = "https://checkout.pipooh.com.br/?id=" + this.pedidoRetorno["pTokenPedido"];

        }
        this.loading = false;
      });
    } else {
      this.loading = false;
      if (!myForm.value.CompradorNome) {
        this.toastr.error("Nome obrigatório");
      }
      else if (!myForm.value.CompradorEmail) {
        this.toastr.error("E-mail obrigatório");
      }
      else if (!myForm.value.CompradorDDD) {
        this.toastr.error("DDD obrigatório");
      }
      else if (!myForm.value.CompradorTelefone) {
        this.toastr.error("Telefone obrigatório");
      }
      else if (!myForm.value.termos) {
        this.toastr.error("Concorde com os Termos e condições de uso.");
      }
      else if (!myForm.value.politica) {
        this.toastr.error("Concorde com as Política de privacidade.");
      }
      else if (myForm.value.CompradorTelefone.length < 9) {
        this.toastr.error("Telefone Inválido");
      }
      else {
        this.toastr.error("Confira os dados", "Dados inválidos");
      }
    }
  }
  onSubmitFrete(myFrete: NgForm) {

    if (myFrete.valid) {
      this.getTotal();
      this.Frete = myFrete.value.CompradorFrete;

      if (myFrete.value.CompradorFrete == null || myFrete.value.CompradorFrete == undefined || myFrete.value.CompradorFrete == "") {
        this.Frete = "0";
      }
      var valorFrete = Number(this.Frete);
      var valorPedido = Number(this.valorTotal);

      this.Total = valorPedido + valorFrete;
      localStorage.setItem('frete', this.Frete);
      this.getTotal();
    }

  }
  continuarComprando() {
    window.location.href = "/evento-pipooh;id=" + this.infoMamae.UserID + ";evento=" + localStorage.getItem('pipoohEvento');

  }

  urlImageLoja(image) {
    return image;
  }

  upload(files: File[]) {
    //pick from one of the 4 styles of file uploads below
    this.basicUpload(files);
  }

  async basicUpload(files: File[]) {
    var formData = new FormData();
    Array.from(files).forEach(f => formData.append('file', f))
    await this.http.post('https://apiv2.pipooh.com.br/convidado/upload', formData)
      .subscribe(event => {
        this.video = event;
        this.video = this.video.payload;

      }, error => this.toastr.error("Tamanho do vídeo não permitido", "Vídeo maior que 16M"))
  }

}
