import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class LembreteService {
  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;



  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any>
    {

        return lastValueFrom(this.http.post(url, formData,{responseType: 'json'})).then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else
            {
                return aux;
            }
        });

    }

  cancelarLembrete(formData) {
    return this.postData(`${this.URLAspNet}lembrete/landing/update/cancel`, formData);
  }
  buscarContatos(formData) {
    return this.postData(`${this.URLAspNet}lembrete/landing/select/contatos`, formData);
  }
  inserirContato(formData) {
    return this.postData(`${this.URLAspNet}lembrete/landing/insert/contato`, formData);
  }
  deleteContato(formData) {
    return this.postData(`${this.URLAspNet}lembrete/landing/delete/contato`, formData);
  }

}
