import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VoucherService } from 'src/app/_services/voucher.service';

@Component({
  selector: 'app-visualizacao-cartaopresente',
  templateUrl: './visualizacao-cartaopresente.component.html',
  styleUrls: ['./visualizacao-cartaopresente.component.css']
})
export class VisualizacaoCartaopresenteComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private _voucherRh: VoucherService) { }
  cartaoID: any;
  cartao: any = [];
  ngOnInit(): void {
    if (this.route.snapshot.params['id']) {
      this.cartaoID = this.route.snapshot.params['id'];

    } else if (this.route.snapshot.queryParamMap.get('id')) {
      this.cartaoID = this.route.snapshot.queryParamMap.get('id')
    }
    this.buscarCartao();
  }
  formatDecimal(string) {
    if (string != "") {
      return parseFloat(string).toFixed(2);
    }
  }

  buscarCartao(){
    this._voucherRh.buscarCartaoPresente({ "id": this.cartaoID }).then(data => {
      this.cartao = data.payload[0];
      console.log("data", data)
    })
  }

}
