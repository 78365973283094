<app-header></app-header>
<div class="content-body" class="hidden-xs" style="text-align: center;">
    <h3 style="color: #01B1AF; margin-top: 30px; margin-bottom: 20px;">O EVENTO DO SEU FILHO <br>
        <b style="color: #01B1AF;font-size: 22px;">com PIPOOH é MELHOR para: </b>
    </h3>
    <div class="row">
        <div class="col-3" style="text-align: end;">
            <img src="../../assets/img/eventos/1.png" style="width: 30%;" alt="">

        </div>
        <div class="col-3" style="text-align: initial;">
            <h6><strong>FILHO</strong></h6>
            <p>Festa melhor;<br>
                Presentes que tanto queria;<br>
                <strong>O presente certo no DIA DA FESTA.</strong>
            </p>
        </div>
        <div class="col-1"></div>
        <div class="col-1">
            <img src="../../assets/img/eventos/2.png" style="width: 100%;" alt="">

        </div>
        <div class="col-4" style="text-align: initial;">
            <h6><strong>PAIS</strong></h6>
            <p>Confirmação de presença;<br>
                Sem presentes repetidos ou perdidos;<br>
                <strong>Tem uma festa moderna e inteligente.</strong>
            </p>
        </div>
        <div class="row" style="background-color: #F1EFEF;">
            <div class="col-2"></div>
            <div class="col-4">
                <img src="../../assets/img/eventos/17.png" style="width: 100%;" alt="">
            </div>
            <div class="col-1"></div>
            <div class="col-5" style="align-items: center; display: flex;">
                <h5 style="color: #01B1AF;">Lista PIPOOH é <strong>oferecer</strong> uma <strong>opção</strong> que
                    respeita <br>
                    o tempo e o dinheiro do seu convidado. </h5>
            </div>
        </div>
    </div>
    <div style="text-align: center;">
        <button mat-stroked-button class="btn-extended-pph" color="primary" (click)="navLink('https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae')">FAZER AGORA MEU EVENTO SEM CUSTOS</button>
    </div>
    <div class="row" style="background-color: #01B1AF; color: #fff;margin-top: 20px; margin-bottom: 20px;">
        <h3 style="margin-top: 25px;">TIPOS DE EVENTOS:</h3>
        <div class="row">
            <div class="col-lg-2" style="padding: 40px;">
                <a target="_blank" href="https://oferta.pipooh.com.br/base-landings-aniversario-af4ff1c575c6ec3eec39">
                    <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                        <img src="/assets/img/evento-aniversario-icon.png" alt="" width="100%">

                    </div>
                    <p style="color:white ; font-size: 12px !important; text-align: center; margin-top: 5px;">
                        ANIVERSÁRIO</p>
                </a>
            </div>
            <div class="col-lg-2" style=" padding: 40px; ">
                <a target="_blank" href="https://oferta.pipooh.com.br/cha_fralda">
                    <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                        <img src="/assets/img/cha-fralda-icon.png" alt="" width="100%">

                    </div>
                    <p style="color:white ; font-size: 12px !important;text-align: center; margin-top: 5px">CHÁ FRALDA
                    </p>
                </a>
            </div>
            <div class="col-lg-2" style=" padding: 40px; ">
                <a target="_blank" href="https://oferta.pipooh.com.br/cha-revelacao ">
                    <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                        <img src="/assets/img/eventos/5.png" alt="" width="100%">

                    </div>
                    <p style="color:white ; font-size: 12px !important;text-align: center; margin-top: 5px">CHÁ
                        REVELAÇÃO
                    </p>
                </a>
            </div>
            <div class="col-lg-2" style="padding: 40px;">
                <a target="_blank" href=" https://oferta.pipooh.com.br/cha_rifa">
                    <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                        <img src="/assets/img/cha-rifa-icon.png" alt="" width="100%">

                    </div>
                    <p style="color:white ; font-size: 12px !important; text-align: center; margin-top: 5px;">CHÁ RIFA
                    </p>
                </a>
            </div>

            <div class="col-lg-2" style="padding: 40px;">
                <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                    <img src="/assets/img/cha-empresarial-icon.png" alt="" width="100%">

                </div>
                <p style="color:white ; font-size: 12px !important;text-align: center; margin-top: 5px">EMPRESARIAL</p>
            </div>

            <div class="col-lg-2" style=" padding: 40px; ">
                <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                    <img src="/assets/img/eventos/4.png" alt="" width="100%">

                </div>
                <p style="color:white ; font-size: 12px !important;text-align: center; margin-top: 5px">EVENTO RELIGIOSO
                </p>
            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-6" style="text-align: end;">
            <video src="/assets/img/eventos/comoFunciona.mp4" poster="/assets/img/eventos/20.png" controls></video>

        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-6">
                    <img src="/assets/img/eventos/8.png" style="width: 70%;" alt="">
                </div>
                <div class="col-6" style="text-align: initial;">
                    <img src="/assets/img/eventos/10.png" style="width: 70%;" alt="">
                </div>
                <div class="col-6">
                    <img src="/assets/img/eventos/12.png" style="width: 70%;" alt="">
                    <br>
                    <a target="_blank" href="/estabelecimentos"
                        style="color: #000;text-decoration: underline; font-size: 13px;">VER PARCEIROS</a>
                </div>
                <div class="col-6" style="text-align: initial;">
                    <img src="/assets/img/eventos/14.png" style="width: 70%;" alt="">
                </div>
            </div>
            
        </div>
    </div>
    <div style="text-align: center;">
        <a target="_blank" href="https://pipooh.com.br/evento-pipooh?id=5544&evento=83459" mat-stroked-button style="border-radius: 25px;background-color: #F8B3CF; color: #fdfafa;
width: 20%;
margin-top: 20px;" color="primary">VER MODELO DE EVENTO</a>
    </div>
    <div style="text-align: center;">
        <button mat-stroked-button class="btn-extended-pph" color="primary" (click)="navLink('https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae')">FAZER AGORA MEU EVENTO SEM CUSTOS</button>
    </div>

    <div class="row" style="margin-top: 20px; margin-bottom: 20px; background-color: #F1EFEF; padding: 20px;">
        <div class="col-1"></div>
        <div class="col-4">
            <img src="/assets/img/eventos/6.png" width="40%" alt=""> <br>
            <a (click)="convidar()" mat-stroked-button style="border-radius: 25px;background-color: #F8B3CF; color: #fdfafa;
            width: 60%;
            margin-top: 20px;" color="primary">VER MODELO DE CONVITE</a>
        </div>
        <div class="col-1"></div>
        <div class="col-6" style="text-align: initial;">
            <br>
            <h4 style="color: #01B1AF;"><strong>CONVITES:</strong> </h4>
            <h3><strong>Sobe sua imagem ou escolhe as opções </strong></h3>
            <h1><strong>+ 300 opções de layouts.</strong></h1>
            <br><br>
            <p>O convite deixa claro que presentear pela PIPOOH é opcional. <img src="/assets/img/eventos/7.png"
                    width="5%" alt=""></p>

        </div>
    </div>
    <h3 style="color: #01B1AF; margin-top: 20px; margin-bottom: 20px;">RECEBA O PRESENTE <br>
        NO DIA DA FESTA: </h3>
    <p>Os convidados presenteiam ONLINE. mas a criança recebe o presente CERTO NA FESTA</p>
    <div class="row">
        <div class="col-2"></div>
        <div class="col-2">
            <img src="/assets/img/eventos/9.png" style="width: 100%;" alt="">
        </div>
        <div class="col-2">
            <img src="/assets/img/eventos/11.png" style="width: 100%;" alt="">
        </div>
        <div class="col-2">
            <img src="/assets/img/eventos/13.png" style="width: 100%;" alt="">
        </div>
        <div class="col-2">
            <img src="/assets/img/eventos/15.png" style="width: 100%;" alt="">
        </div>
        <div class="col-2"></div>
    </div>
    <div style="text-align: center;">
        <button mat-stroked-button class="btn-extended-pph"  color="primary" (click)="navLink('https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae')">FAZER AGORA MEU EVENTO SEM CUSTOS</button>
    </div>

    <div class="row" style="margin-top: 20px; background-color: #F1EFEF; padding: 20px;">
        <div class="col-2"></div>
        <div class="col-4" style="text-align: end;">
            <a target="_blank" href="https://www.instagram.com/stories/highlights/18014976742279926/"><img
                    src="/assets/img/eventos/19.png" width="100%" alt=""></a>
        </div>
    </div>

</div>
<div class="visible-xs">
    <div class="content-body"  style="text-align: center;">
        <h3 style="color: #01B1AF; margin-top: 40px; margin-bottom: 20px;">O EVENTO DO SEU FILHO <br>
            com PIPOOH é MELHOR para: </h3>
        <div class="row">
            <div class="col-4" style="text-align: end;">
                <img src="../../assets/img/eventos/1.png" style="width: 100%;padding: 10px;" alt="">
    
            </div>
            <div class="col-8" style="text-align: initial;">
                <h6><strong>FILHO</strong></h6>
                <p style="font-size: 13px;">Festa melhor;<br>
                    Presentes que tanto queria;<br>
                    <strong>O presente certo no DIA DA FESTA.</strong>
                </p>
            </div>
            <div class="col-12" style="    margin-bottom: 20px;">
                <img src="../../assets/img/eventos/17.png" style="width: 100%;" alt="">
            </div>
            <div class="col-4">
                <img src="../../assets/img/eventos/2.png" style="width: 100%;padding: 10px;" alt="">
    
            </div>
            <br>
            <div class="col-8" style="text-align: initial;">
                <h6><strong>PAIS</strong></h6>
                <p style="font-size: 13px;">Confirmação de presença;<br>
                    Sem presentes repetidos ou perdidos;<br>
                    <strong>Tem uma festa moderna e inteligente.</strong>
                </p>
            </div>
        </div>
        <h6 style="color: #01B1AF; margin-top: 10px;">Lista PIPOOH é <strong>oferecer</strong> uma <strong>opção</strong>
            que
            respeita <br>
            o tempo e o dinheiro do seu convidado. </h6>
        <div style="text-align: center;">
            <button mat-stroked-button class="btn-extended-pph" color="primary" (click)="navLink('https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae')">FAZER AGORA MEU EVENTO SEM CUSTOS</button>
        </div>
        <div class="row" style="background-color: #01B1AF; color: #fff;margin-top: 20px; margin-bottom: 20px;">
            <h3 style="margin-top: 25px;">TIPOS DE EVENTOS:</h3>
            <div class="row">
                <carousel style="width: 100%; margin-bottom: 20px;">
                    <div class="carousel-cell">
                        <div class="col-lg-3" style="padding: 20px;">
                            <a target="_blank"
                                href="https://oferta.pipooh.com.br/base-landings-aniversario-af4ff1c575c6ec3eec39">
                                <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                                    <img src="/assets/img/evento-aniversario-icon.png" alt="" width="100%">
    
                                </div>
                                <p style="color:white ; font-weight: 600 !important; text-align: center;">ANIVERSÁRIO</p>
                            </a>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="col-lg-3" style=" padding: 20px; ">
                            <a target="_blank" href="https://oferta.pipooh.com.br/cha_fralda">
                                <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                                    <img src="/assets/img/cha-fralda-icon.png" alt="" width="100%">
    
                                </div>
                                <p style="color:white ; font-weight: 600 !important;text-align: center;">CHÁ FRALDA</p>
                            </a>
                        </div>
    
                    </div>
                    <div class="carousel-cell">
                        <div class="col-lg-3" style="padding: 20px;">
                            <a target="_blank" href=" https://oferta.pipooh.com.br/cha_rifa">
                                <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                                    <img src="/assets/img/cha-rifa-icon.png" alt="" width="100%">
                                </div>
                                <p style="color:white ; font-weight: 600 !important; text-align: center;">CHÁ RIFA</p>
                            </a>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="col-lg-3" style="padding: 20px;">
                            <a target="_blank" href="https://oferta.pipooh.com.br/cha-revelacao ">
                                <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                                    <img src="/assets/img/eventos/5.png" alt="" width="100%">
                                </div>
                                <p style="color:white ; font-weight: 600 !important; text-align: center;">CHÁ REVELAÇÃO</p>
                            </a>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="col-lg-3" style="padding: 20px;">
                            <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                                <img src="/assets/img/cha-empresarial-icon.png" alt="" width="100%">
    
                            </div>
                            <p style="color:white ; font-weight: 600 !important;text-align: center;">EMPRESARIAL</p>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="col-lg-3" style="padding: 20px;">
                            <div style="padding: 40px;background-color:white ; border-radius: 200px;">
                                <img src="/assets/img/eventos/4.png" alt="" width="100%">
    
                            </div>
                            <p style="color:white ; font-weight: 600 !important;text-align: center;">EVENTO RELIGIOSO</p>
                        </div>
                    </div>
                </carousel>
            </div>
    
        </div>
    
        <div class="row" style="padding: 20px;">
            <div class="col-12">
                <video src="/assets/img/eventos/comoFunciona.mp4" width="95%" poster="/assets/img/eventos/20.png"
                    controls></video>
    
            </div>
            <div class="col-6">
                <img src="/assets/img/eventos/8.png" style="width: 100%;" alt="">
            </div>
            <div class="col-6">
                <img src="/assets/img/eventos/10.png" style="width: 100%;" alt="">
            </div>
            <div class="col-6">
                <img src="/assets/img/eventos/12.png" style="width: 100%;" alt="">
                <a target="_blank" href="/estabelecimentos"
                    style="color: #000;text-decoration: underline;font-size: 13px;">VER PARCEIROS</a>
            </div>
            <div class="col-6">
                <img src="/assets/img/eventos/14.png" style="width: 100%;" alt="">
            </div>
            <div class="col-2"></div>
        </div>
        <div style="text-align: center;">
            <a target="_blank" href="https://pipooh.com.br/evento-pipooh?id=5544&evento=83459" mat-stroked-button style="border-radius: 25px;background-color: #F8B3CF; color: #fdfafa;
    width: 60%;
    margin-top: 20px;" color="primary">VER MODELO DE EVENTO</a>
        </div>
        <div style="text-align: center;">
            <button mat-stroked-button class="btn-extended-pph" color="primary" (click)="navLink('https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae')">FAZER AGORA MEU EVENTO SEM CUSTOS</button>
        </div>
    
        <div class="row" style="margin-top: 20px; margin-bottom: 20px; background-color: #F1EFEF; padding: 20px;">
            <div class="col-4">
                <img src="/assets/img/eventos/6.png" width="100%" alt=""> <br>
    
            </div>
            <div class="col-8" style="text-align: initial;">
                <h6 style="color: #01B1AF;"><strong>CONVITES:</strong></h6>
                <p><strong style="font-size: 11px;">Sobe sua imagem ou escolhe as opções </strong><br>
                    <strong style="font-size: 17px;">+ 300 opções de layouts.</strong>
                </p>
    
            </div>
            <div style="text-align: end;">
                <a (click)="convidar()" mat-stroked-button style="border-radius: 25px;background-color: #F8B3CF; color: #fdfafa;
                width: 70%;
                margin-top: 10px; margin-bottom: 10px;" color="primary">VER MODELO DE CONVITE</a>
            </div>
    
            <br>
            <p style="font-size: 11px;">O convite deixa claro que presentear pela PIPOOH é opcional. <img
                    src="/assets/img/eventos/7.png" width="7%" alt=""></p>
        </div>
        <div style="padding: 20px;">
            <h3 style="color: #01B1AF; margin-top: 20px; margin-bottom: 20px;">RECEBA O PRESENTE <br>
                NO DIA DA FESTA: </h3>
            <p>Os convidados presenteiam ONLINE. mas a criança recebe o presente CERTO NA FESTA</p>
        </div>
    
        <div class="row" style="padding: 20px;">
            <div class="col-6">
                <img src="/assets/img/eventos/9.png" style="width: 100%;" alt="">
            </div>
            <div class="col-6">
                <img src="/assets/img/eventos/11.png" style="width: 100%;" alt="">
            </div>
            <div class="col-6">
                <img src="/assets/img/eventos/13.png" style="width: 100%;" alt="">
            </div>
            <div class="col-6">
                <img src="/assets/img/eventos/15.png" style="width: 100%;" alt="">
            </div>
        </div>
        <div style="text-align: center;">
            <button mat-stroked-button class="btn-extended-pph" (click)="navLink('https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae')" color="primary">FAZER AGORA MEU EVENTO SEM CUSTOS</button>
        </div>
    
        <div class="row" style="margin-top: 20px; background-color: #F1EFEF; padding: 20px;">
            <a target="_blank" href="https://www.instagram.com/stories/highlights/18014976742279926/"><img
                    src="/assets/img/eventos/19.png" width="100%" alt=""></a>
        </div>
    
    </div>
</div>


<app-footer></app-footer>