import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EstabelecimentoService } from 'src/app/_services/estabelecimento.service';
import { ProductsService } from 'src/app/_services/products.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { PedidoService } from 'src/app/_services/pedido.service';
import { cpf } from 'cpf-cnpj-validator';
import * as moment from 'moment';

@Component({
  selector: 'app-cartao-presente',
  templateUrl: './cartao-presente.component.html',
  styleUrls: ['./cartao-presente.component.css']
})
export class CartaoPresenteComponent implements OnInit {
  //Modais
  @ViewChild('modalComprador') modalComprador;
  //
  public URLNOVA = "https://img.pipooh.com.br/"
  // Estabelecimento
  estabelecimento: any = [];
  estabelecimentos: any = [];
  estabelecimentoDetalhe: any = [];
  estabelecimentoImagem: any = [];
  //Filtro
  nomeEstabelecimento: any;
  busca: any;
  //Paginação
  page = 1;
  pageSize = 6;
  collectionSize = [];
  //
  loading: any = false;
  loadingBusca: any = 0;
  closeResult = '';
  products: any = [];
  categorys: any = "";
  category: any = "";
  description: any = "";
  search: any = "";
  product: any = [];
  more: boolean = false;
  imagens: any;
  chosenImg: any;
  selectedCategory: any = "";
  //pedido
  valor: any;
  pedido: any = [];
  //Formularios
  formCartao: FormGroup;
  formComprador: FormGroup;
  submitted: any = false;


  constructor(private _estabelecimentoService: EstabelecimentoService,
    private modalService: NgbModal,
    private _prodService: ProductsService,
    private _usuarioService: UsuarioService,
    private _pedidoService: PedidoService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formCartao = this.formBuilder.group({
      nome: ['', [Validators.required]],
      nomeCrianca: [''],
      tipo: ['', [Validators.required]],
      email: ['', []],
      telefone: ['', [ ]],
      valor: [''],
      mensagem: ['']
    });
    this.formComprador = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required, , Validators.email]],
      cpf: ['', [Validators.required, Validators.minLength(11)]],
      celular: ['', [Validators.required, Validators.minLength(11)]],
    });
    this.buscarEstabelecimentoLista("", "");
    this.getCategorias();
    this.imagens = JSON.parse('{"payload":[{"id":"1"}, {"id":"2"}, {"id":"3"}]}');
  }

  formatDateBD(date) {
    if (date) {
      var dateSent = new Date(date);
      var startTime = moment(dateSent).format("YYYY/MM/DD");
      return startTime;
    }
  }


  onSubmitFormCartao() {
    this.submitted = true;
    this.loading = true;
    if (!this.formCartao.valid) {
      if(this.formCartao.value.telefone && this.formCartao.value.telefone.length <= 11){
        this.toastr.error("Telefone inválido");
      }
      this.loading = false;
      this.submitted = false;
      return false;
    } else {
      this.loading = false;
      this.modalService.dismissAll()
      this.formCartao.value.valor = this.valor;
      this.modalService.open(this.modalComprador, { centered: true }).result.then(() => { }, () => { });
      
    }
  }
  onSubmitFormComprador() {
    this.submitted = true;
    this.loading = true;
    if (!this.formComprador.valid) {
      return false;
    } else {
      if(this.formComprador.value.nome.indexOf(' ') <= 0){
        this.toastr.error('Informe o nome completo', 'Falha no cadastro'); this.loading = false; return false;
      };
      if (cpf.isValid(this.formComprador.value.cpf) == false) { this.toastr.error('CPF inválido', 'Falha no cadastro'); this.loading = false; return false; }
      
          this._pedidoService.inserirPedidoCartaoPresente({"cartao": this.formCartao.value, "comprador": this.formComprador.value}).then(data => {
            if (data.success) {
              var aux = data.payload[0];
              this.loading = false;
              window.location.href = "https://checkout.pipooh.com.br/?id=" + aux["token"];
            } else {
              this.loading = false;
            }
          }).catch(e => {
            this.loading = false;
          })
    }
  }

  buscarEstabelecimentoLista(busca, categoria) {
    this.loadingBusca = 1;
    this._estabelecimentoService.buscarEstabelecimentos({ "busca": busca, "categoria": categoria }).then(data => {
      var aux = data;
      this.estabelecimentos = aux.payload;
      this.loadingBusca = 0;
      for (var i = 0; i < this.estabelecimentos.length; i++) {
        if (this.estabelecimentos[i].foto) {
          this.estabelecimentoImagem.push({ "path": "https://img.pipooh.com.br/" + this.estabelecimentos[i].foto });
        }
      }
    });
    this.nomeEstabelecimento = null;

  }

  changeImg(i) {
    this.chosenImg = i;
  }

  formatDecimal(string) {
    if (string != "") {
      return parseFloat(string).toFixed(2);
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title,', size: 'lg', windowClass: 'modal', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getSearch() {
    this.products = [];
    this.category = "Descricao";
    this.description = this.search;
    if (this.search == "") { this.description = ''; this.category = ''; }
    this.getProducts(this.category, this.description);
  }
  getProducts(productFilter, productDescription) {
    this._prodService.getProductsListAll({ "pProductsFiltro": productFilter, "pProductsDescricao": productDescription, "pFranquia": "1" }).then(data => {
      var prod: any = data;
      this.products = prod.payload;
      this._prodService.getProductsImagem({ "fkProduto": "" }).then(data2 => {
        var imgs: any = data2;
        this.imagens = imgs.payload;
        for (var i = 0; i < this.products.length; i++) {
          this.products[i].imagens = [];
          this.products[i].imagens.push(this.products[i].ProductsImage);
          for (var h = 0; h < this.imagens.length; h++) {
            if (this.products[i].ProductsID == this.imagens[h].fkProduto) {
              this.products[i].imagens.push(this.imagens[h].produtoImagem);
            }
          }
        }
      })
    })
  }
  getCategorias() {
    this._prodService.getCategorias({}).then(data => {

      var cat: any = data;
      this.categorys = cat.payload;
      if (this.route.snapshot.params['filtro']) {
        this.selectedCategory = this.route.snapshot.params['filtro'];
      }

      this.onSelect();
    })

  }

  onSelect() {

    this.products = [];
    this.category = "Categoria";
    this.description = this.selectedCategory;
    if (this.selectedCategory == "") { this.description = ''; this.category = ''; }

    this.getProducts(this.category, this.description);
  }

  openProduto(prod) {
    this.chosenImg = 0;
    this.product.obs = "";
    this.more = false;
    document.getElementById("openModalProduto").click();
    this.product = prod;
  }
  abrirCompra(content) {
    if (this.valor == "" || this.valor == null || this.valor < 0) {
      this.toastr.error("Valor inválido");
      return;
    }
    this.modalService.open(content, { centered: true });
  }

  urlImage(image) {
    return this.URLNOVA + image;
  }
  urlImageLoja(image) {
    return image;
  }

}

