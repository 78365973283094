import { Component, OnInit, AfterViewInit,ElementRef } from '@angular/core';
import { EventoEstabelecimentoService } from '../_services/evento-estabelecimento.service';
import { PedidoIngressoService } from '../_services/pedigo-ingresso.service';
import * as moment from 'moment';
import {  ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-ingresso-estabelecimento',
  templateUrl: './ingresso-estabelecimento.component.html',
  styleUrls: ['./ingresso-estabelecimento.component.css']
})
export class IngressoEstabelecimentoComponent implements AfterViewInit, OnInit {

  constructor(
    private _eventoEstabelecimentoService: EventoEstabelecimentoService,
    private _pedidoIngressoService: PedidoIngressoService,
    private toastr: ToastrService,
    private elemento: ElementRef
    ) { }
  codigoIngresso: any;
  ingressos: any = [];
  ingressosFila: any = [];
  posicaoFila: any;
  evento: any = [];

  ngAfterViewInit(): void {
    if (environment.domain == 'https://sistema.zull.com.br/') {
      this.elemento.nativeElement.ownerDocument.body.style.backgroundColor = 'white';
    }
  }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    this.codigoIngresso = urlParams.get('id');
    this.getInfoIngresso();
  }
  formatDate(date) {
    if (date) {
      var dateSent = new Date(date);
      var startTime = moment(dateSent).add(0, 'days').format('DD/MM/YYYY');
      return startTime;
    }
  }
  formatDateBD(date) {
    if (date) {
      var dateSent = new Date(date);
      var startTime = moment(dateSent).format("YYYY-MM-DD");
      return startTime;
    }
  }

  formatDecimal(string) {
    if (string != '') {
      return parseFloat(string).toFixed(2);
    }
  }
  getInfoIngresso() {
    this._pedidoIngressoService.buscarIngresso({ "codigo": this.codigoIngresso }).then((data) => {
      this.ingressos = data.payload.ingressos;
      this.evento = data.payload.evento;
      this.ingressosFila = data.payload.ingressosFila;
      for(var i = 0; i < this.ingressosFila.length; i++){
        if(this.ingressosFila[i].fkPedidoIngresso == this.evento.id){
          this.posicaoFila = i++;
        }
      }
      this.evento.forEach(ev => {
        
      });
      console.log("data", data)
    });
  }

  cancelarIngresso(){
    this._pedidoIngressoService.cancelarIngresso({ "codigo": this.codigoIngresso }).then((data) => {
      this.getInfoIngresso();
      this.toastr.success("Ingressos cancelado.");
    });
  }
  aCaminho(){
    this._pedidoIngressoService.aCaminho({ "codigo": this.codigoIngresso }).then((data) => {
      this.getInfoIngresso();
      this.toastr.success("Ingressos atualizado.");
    });
  }

}
