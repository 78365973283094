<app-header></app-header>
<div class="row justify-content-center">
    <div class="col-lg-12" style="padding: 50px;text-align: center;">

        <p><strong>Lista de Convidados</strong></p>
        <table class="table table-striped text-left table-grow text-center">
            <thead>
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">WhatsApp</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let convidado of contatos">
                    <td>{{ convidado.nome }}</td>
                    <td>{{ convidado.telefone }}</td>
                    <td>
                        <button  (click)="deleteConvidado(convidado.id)" mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon">
                            <mat-icon (click)="deleteConvidado(convidado.id)">delete</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="row justify-content-center" style="padding: 40px">
            <div class="col-lg-3" style="padding: 10px">
                <input name="name" class="form-control" placeholder="Nome" [(ngModel)]="contato.nome" />
            </div>
            <div class="col-lg-3" style="padding: 10px">
                <input type="text" mask="(00)00000-0000" [(ngModel)]="contato.telefone" class="form-control" name=""
                    id="" placeholder="WhatsApp" required />
            </div>
            <div class="col-lg-2" style="text-align: center; padding: 10px">
                <button class="btn btn-success" [disabled]="loading"
                    [ngClass]="{'m-loader m-loader--right m-loader--light': loading }" (click)="inserirContato();">
                    Adicionar
                </button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>