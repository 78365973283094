import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VoucherService } from 'src/app/_services/voucher.service';

@Component({
  selector: 'app-voucher-rh',
  templateUrl: './voucher-rh.component.html',
  styleUrls: ['./voucher-rh.component.css']
})
export class VoucherRhComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private _voucherRh: VoucherService) { }
  voucherRhID: any;
  empresaID: any;
  voucher: any = [];
  nome: any;
  ngOnInit(): void {
    if (this.route.snapshot.params['id']) {
      this.voucherRhID = this.route.snapshot.params['id'];

    } else if (this.route.snapshot.queryParamMap.get('id')) {
      this.voucherRhID = this.route.snapshot.queryParamMap.get('id')
    }
    if (this.route.snapshot.params['empresa']) {
      this.empresaID = this.route.snapshot.params['empresa'];

    } else if (this.route.snapshot.queryParamMap.get('empresa')) {
      this.empresaID = this.route.snapshot.queryParamMap.get('empresa')
    }

    if (this.voucherRhID) {
      this.buscarVoucher();
    } else if (this.empresaID){
      this.buscarVoucherTeste();
    }
  }
  buscarVoucher() {
    this._voucherRh.buscarVoucherRh({ "id": this.voucherRhID }).then(data => {
      this.voucher = data.payload[0];
      this.nome = this.voucher.nome.split(" ")[0];
      if (!this.voucher.voucher || this.voucher.voucher == null || this.voucher.voucher == "") {
        this.voucher.voucher = "imagens/rh/voucher/PIPOOH_Voucher_Desk.jpg";
        this.voucher.voucherMobile = "imagens/rh/voucher/PIPOOH_Voucher_mobile.jpg";
      }
      console.log("voucher", this.voucher)
    })
  }
  buscarVoucherTeste() {
    this._voucherRh.buscarVoucherRhTeste({ "id": this.empresaID }).then(data => {
      this.voucher = data.payload[0];
      this.nome = this.voucher.nome.split(" ")[0];
      if (!this.voucher.voucher || this.voucher.voucher == null || this.voucher.voucher == "") {
        this.voucher.voucher = "imagens/rh/voucher/PIPOOH_Voucher_Desk.jpg";
        this.voucher.voucherMobile = "imagens/rh/voucher/PIPOOH_Voucher_mobile.jpg";
      }
      console.log("voucher", this.voucher)
    })
  }
}
