
<app-header></app-header>
<div class="content-body" style="padding: 20px;">

    <div class="row justify-content-center" >
        <!-- <div class="col-12"> -->
        <div class="row justify-content-center">
            <h1 style="text-align: center;margin-top: 10px;">O MELHOR PRESENTE PARA EVENTOS INFANTIS </h1>
            <p style="color:#01b1af;text-align: center;">Você ainda contribui para o futuro da criança.
                <br> 2% do valor é revertido em previdência. </p>
            <h3 style="text-align: center; margin-top: 10px;"><strong></strong> Valor desejado:</h3>
            <div class="col-lg-3" (click)="valor = 50; abrirCompra(modalCartao)">
                <img src="../../../assets/img/cartao50.jpeg" width="100%" style="cursor: pointer; margin-bottom: 10px;"
                    alt="">
            </div>
            <div class="col-lg-3 " (click)="valor = 75; abrirCompra(modalCartao)">
                <img src="../../../assets/img/cartao75.jpeg" width="100%" style="cursor: pointer; margin-bottom: 10px;"
                    alt="">
            </div>
            <div class="col-lg-3 " (click)="valor = 100; abrirCompra(modalCartao)">
                <img src="../../../assets/img/cartao100.jpeg" width="100%" style="cursor: pointer; margin-bottom: 10px;"
                    alt="">
            </div>
            <div class="col-lg-3">
                <label for="">Outro valor:</label>
                <input type="text" style="width: 50%;" class="form-control" placeholder="R$0.00"
                    [dropSpecialCharacters]="false" mask="0*.00" [(ngModel)]="valor">
            </div>

        </div>
        <br>

        <p style="text-align: center; margin-top: 20px;"><button class="btn btn-primary hidden-xs" style="width: 30%;"
                (click)="abrirCompra(modalCartao)">Comprar Cartão Presente</button>
            <button class="btn btn-primary d-lg-none" style="width: 100%;     height: 100%;
        font-size: 20px;" (click)="abrirCompra(modalCartao)">Comprar Cartão Presente</button>
        </p>

        
    </div>
    <div >
        <ul >
            <li><strong>Funcionamento:</strong></li>
            <li>Idade: 0 à 15 anos</li>
            <li>+1Mil lojas que aceitam PIPOOH (abaixo marcas e produtos)</li>
            <li>Cartão enviado de forma online</li>
            <li>Para que tipos de eventos: Chás de bebê, Aniversários, Batizado, Nascimento, Debutante</li>
        </ul>
    </div>
    <div class="row justify-content-center" *ngIf="estabelecimentoImagem.length > 0">
        <div class="col-xs-12 col-md-10 p-xs-1" style="margin-top: 15px">
            <h3 style=" color:#01b1af;  text-align: center;">Onde usar os créditos:</h3>
            <carousel [images]="estabelecimentoImagem" [borderRadius]="100" [cellWidth]="100" [height]="100">
            </carousel>
            <br>
        </div>
    </div>
    <br>
    <hr>
    <br>

    

</div>
<app-footer></app-footer>
<!-- Modais a partir daqui -->

<ng-template #modalProduto let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="container-fluid ">
            <div class="row">
                <div class="col-md-6">
                    <div *ngFor="
                    let img of product.imagens;
                    let i = index;
                    let firstItem = first
                  " class="tab-pane active" id="imagem{{ i }}" aria-expanded="true">
                        <img *ngIf="!product.fkProdutos_LojaIntegrada" [hidden]="this.chosenImg != i"
                            src="{{ this.urlImage(img) }}" width="100%" style="border-radius: 25px" />
                        <img *ngIf="product.fkProdutos_LojaIntegrada" [hidden]="this.chosenImg != i"
                            src="{{ this.urlImageLoja(img) }}" width="100%" style="border-radius: 25px" />
                    </div>

                    <ul *ngIf="product"
                        class="nav nav-pills nav-pills--brand m-nav-pills--align-right m-nav-pills--btn-pill m-nav-pills--btn-sm"
                        role="tablist">
                        <li *ngFor="let prod of product.imagens ; let i=index;let firstItem = first;"
                            class="nav-item m-tabs__item">
                            <a class="nav-link m-tabs__link" [ngClass]="{ active: firstItem }" data-toggle="tab"
                                (click)="changeImg(i)" role="tab">{{i+1}}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <h5>{{this.product.ProductsName}}</h5>
                    <div *ngIf="this.more">
                        <div [innerHTML]="this.product.ProductsDescription">
                        </div>
                    </div>

                    <span *ngIf="!this.more" (click)="this.more = true" class="more">...Ler mais</span>
                    <h4 class="mt-3" style="color: #01b1af;">R$ {{this.formatDecimal(this.product.ProductsPrice)}}</h4>
                    <br>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<button id="openModalProduto" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalProduto)"
    data-target="#openModalProduto">Launch demo modal</button>


<ng-template #modalCartao let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="row" style=" padding: 10px;">
            <p>Valor do presente: R$ {{formatDecimal(valor)}}</p>
            <form (ngSubmit)="onSubmitFormCartao()" [formGroup]="formCartao">
                <h3>Quem receberá o presente:</h3>
                <select class="form-control" formControlName="tipo" id="tipo">
                    <option value="" disabled selected>
                        Tipo do evento
                      </option>
                      <option value="Aniversário">Aniversário</option>
                      <option value="Chá bar">Chá bar</option>
                      <option value="Chá de boas vindas">Chá de boas vindas</option>
                      <option value="Chá de revelação">Chá de revelação</option>
                      <option value="Chá fralda/bebê">Chá fralda/bebê</option>
                      <option value="Chá fralda/rifa">Chá fralda/rifa</option>
                      <option value="Dia das crianças">Dia das crianças</option>
                      <option value="Fute Fralda">Fute Fralda</option>
                      <option value="Mêsversário">Mêsversário</option>
                      <option value="Natal">Natal</option>
                </select>
                <div class="error"
                    *ngIf="formCartao.get('tipo')?.errors  && (formCartao.get('tipo')?.touched  || submitted)">
                    Tipo obrigatório
                </div>
                <br>

                <input type="text" class="form-control" placeholder="Nome do responsável" formControlName="nome" id="nome">
                <div class="error"
                    *ngIf="formCartao.get('nome')?.errors  && (formCartao.get('nome')?.touched  || submitted)">
                    Nome obrigatório
                </div>
                <br>

                <input type="text" class="form-control" placeholder="Nome da criança" formControlName="nomeCrianca"
                    id="nomeCrianca">

                <br>
                <input type="text" class="form-control" placeholder="E-mail de envio do voucher"
                    formControlName="email" id="email">
                <br>
                <input type="text" class="form-control" mask="(00)00000-0000" placeholder="Whatsapp de envio do voucher"
                    formControlName="telefone" id="telefone">
                
                <br>
                <textarea type="text" style="background-color: #d9d8d8;" class="form-control"  placeholder="Mensagem"
                    formControlName="mensagem" id="mensagem"></textarea>
                
                <br>
                <div style="text-align: center;">
                    <button class="btn btn-primary" type="submit" [disabled]="loading"
                        [ngClass]="{'m-loader m-loader--right m-loader--light': loading }">
                        Próximo
                    </button>
                </div>

            </form>
            <br>
        </div>
    </div>
</ng-template>

<ng-template #modalComprador let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="row" style=" padding: 10px;">
            <form (ngSubmit)="onSubmitFormComprador()" [formGroup]="formComprador">
                <h3>Informações do comprador:</h3>


                <input type="text" class="form-control" placeholder="Nome Completo*" formControlName="nome" id="nome">
                <div class="error"
                    *ngIf="formComprador.get('nome')?.errors  && (formComprador.get('nome')?.touched  || submitted)">
                    Nome obrigatório
                </div>
                <br>
                <input type="text" class="form-control" placeholder="E-mail*" formControlName="email" id="email">
                <div class="error"
                    *ngIf="formComprador.get('email').errors?.required  && (formComprador.get('email')?.touched  || submitted)">
                    E-mail obrigatório
                </div>
                <div class="error"
                    *ngIf="formComprador.get('email').errors?.email && formComprador.get('email')?.touched">
                    E-mail inválido
                </div>
                <br>
                <input type="text" mask="000.000.000-00" class="form-control" placeholder="CPF *" formControlName="cpf"
                    id="cpf">
                <div class="error"
                    *ngIf="(formComprador.get('cpf')?.errors && !formComprador.get('cpf').errors?.minlength) && submitted">
                    CPF obrigatório
                </div>
                <div class="error"
                    *ngIf="formComprador.get('cpf').errors?.minlength && (formComprador.get('cpf')?.touched  || submitted)">
                    CPF precisa conter 11 dígitos
                </div>
                <br>
                <input type="text" class="form-control" placeholder="Celular*" mask="(00)00000-0000"
                    formControlName="celular" id="celular">
                <div class="error"
                    *ngIf="(formComprador.get('celular')?.errors && !formComprador.get('celular')?.errors?.minlength  )  &&  submitted">
                    Celular obrigatório
                </div>
                <div class="error"
                    *ngIf="formComprador.get('celular')?.errors?.minlength  && (formComprador.get('celular')?.touched || submitted)">
                    Celular precisa conter 11 dígitos
                </div>
                <br>
                <div style="text-align: center;">
                    <button class="btn btn-primary" type="submit" [disabled]="loading"
                        [ngClass]="{'m-loader m-loader--right m-loader--light': loading }">
                        Comprar
                    </button>
                </div>

            </form>
            <br>
        </div>
    </div>
</ng-template>