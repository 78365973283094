<app-header></app-header>
<div class="content-body" style="text-align: center;">
    <div style="color: #fff; background-color: #01B1AF; ">
        <h3 style="padding-top: 20px;">PROGRAMA DE BENEFÍCIOS
            PIPOOH </h3>
        <p>Somos o único programa de recompensa do Brasil dedicado aos filhos. <br>
            Aqui você tem várias formas de juntar
            pontos e trocá-los por <br> <b style="color: #F9E291;"> Previdência, Produtos ou Viagens.</b>
        </p>
        <img src="/assets/img/eventos/16.png" class="hidden-xs" width="5%" alt="">
        <div class="visible-xs">
            <img src="/assets/img/eventos/16.png" width="20%" alt="">
        </div>
        <div class="row" style="padding: 10px; margin-top: 20px;">
            <div class="col-6" style="text-align: end;">
                <img src="/assets/img/eventos/21.png" class="hidden-xs" width="50%" alt="">
                <img src="/assets/img/eventos/21.png" class="visible-xs" width="100%" alt="">
            </div>
            <div class="col-6 visible-xs">
                <!-- <div (click)="ancora('acumular')"
                    style="background-color: #fff; color: #000; box-shadow: -10px 5px 0px 2px #f8b3cf; border-radius: 20px;">
                    <p style="color: #797676;">
                        <b>JUNTAR</b> <br>
                        pontos
                    </p>
                </div>
                <br>
                <div (click)="ancora('usar')"
                    style="background-color: #fff; color: #000; box-shadow: -10px 5px 0px 2px #f8b3cf; border-radius: 20px">

                    <p style="color: #797676;"><b>USAR</b> <br>
                        pontos</p>

                </div> -->
                <img style="cursor: pointer;" (click)="ancora('acumular')" src="/assets/img/eventos/37.png" width="100%"
                    alt="">
                <br>
                <img style="cursor: pointer;" (click)="ancora('usar')" src="/assets/img/eventos/38.png" width="100%"
                    alt="">
            </div>
            <div class="col-6 hidden-xs" style="margin-top: 30px; text-align: left;">

                <img style="cursor: pointer;" (click)="ancora('acumular')" src="/assets/img/eventos/37.png" width="50%"
                    alt="">
                <br>
                <img style="cursor: pointer;" (click)="ancora('usar')" src="/assets/img/eventos/38.png" width="50%"
                    alt="">
                <!-- <div (click)="ancora('acumular')"
                    style="cursor: pointer; background-color: #fff; width: 50%; height: 25%; color: #000; box-shadow: -10px 5px 0px 2px #f8b3cf; border-radius: 20px;">
                   
                    <p style="color: #797676;">
                        <b>JUNTAR</b> <br>
                        pontos
                    </p>
                </div>
                <br>
                <div (click)="ancora('usar')"
                    style="cursor: pointer; background-color: #fff; width: 50%; height: 25%; color: #000; box-shadow: -10px 5px 0px 2px #f8b3cf; border-radius: 20px">

                    <p style="color: #797676;"><b>USAR</b> <br>
                        pontos</p>

                </div> -->

                <br>

            </div>
        </div>
    </div>
    <div style="margin-top: 20px;">
        <div style="background-color: #F3F4F7;text-align: center; padding: 20px;">
            <h5><strong>Acumule pontos para ser Pais: </strong></h5>
            <table class="table table-striped text-center" id="acumular">
                <thead>
                    <tr>
                        <th scope="col">Categoria</th>
                        <th scope="col">Pontos</th>
                        <th scope="col">Produtos</th>
                        <th scope="col">YouPlay</th>
                        <th scope="col" class="hidden-xs"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cat of this.categorias; let i = index">
                        <td>{{ cat.categoria }} <br>
                            <span *ngIf=" i == 0" style="font-size: 11px;"> (Pais Iniciantes)</span>
                            <span *ngIf=" i == 1" style="font-size: 11px;"> (Pais TOP)</span>
                            <span *ngIf=" i == 2" style="font-size: 11px;"> (Super Pais)</span>
                            <span *ngIf=" i == 3" style="font-size: 11px;"> (Pais Venerados e inteligentes)</span>
                        </td>
                        <td *ngIf="cat.pontos == 0">-</td>
                        <td *ngIf="cat.pontos > 0">
                            {{ formatMilhares(cat.pontos) }}
                        </td>
                        <td *ngIf="cat.produtos != 1000">{{ cat.produtos }}</td>
                        <td *ngIf="cat.produtos == 1000">Ilimitado</td>
                        <td *ngIf="cat.categoria == 'Baby'">5%</td>
                        <td *ngIf="cat.categoria == 'Top'">10%</td>
                        <td *ngIf="cat.categoria == 'Super'">15%</td>
                        <td *ngIf="cat.categoria == 'Vip'">25%</td>
                        <td *ngIf="i == 0" class="hidden-xs">
                            <img style="height: 36px; color: #01b1af"
                                src="https://img.icons8.com/ios/50/000000/babys-room.png" />
                        </td>

                        <td *ngIf="i == 1" class="hidden-xs">
                            <img style="height: 36px; color: #01b1af"
                                src="https://img.icons8.com/pastel-glyph/64/000000/medal--v2.png" />
                        </td>

                        <td *ngIf="i == 2" class="hidden-xs">
                            <img style="height: 36px; color: #01b1af"
                                src="https://img.icons8.com/ios/50/000000/medal.png" />
                        </td>

                        <td *ngIf="i == 3" class="hidden-xs">
                            <img style="height: 36px; color: #01b1af"
                                src="https://img.icons8.com/ios-glyphs/30/000000/diamond--v1.png" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h5 style="color: #01B1AF;margin-top: 10px;">Como acumular pontos</h5>

        <div class="visible-xs">
            <div class="row" style="padding: 20px; text-align: center;">
                <div class="col-5"
                    style="border: 1px solid #01b1af; border-radius: 20px; margin-bottom: 20px;padding: 10px;">
                    <div class="row">
                        <div class="col-3">
                            <mat-icon style="font-size: 60px; color: #f8b3cf;">card_giftcard</mat-icon>

                        </div>
                        <div class="col-2"></div>
                        <div class="col-6">
                            <p style="font-size:13px">Ganhando
                                presentes </p>
                        </div>
                    </div>

                </div>
                <div class="col-1"></div>
                <div class="col-5"
                    style="border: 1px solid #01b1af; border-radius: 20px; margin-bottom: 20px;padding: 10px;">
                    <div class="row">
                        <div class="col-3">
                            <!-- <mat-icon style="font-size: 60px; color: #f8b3cf;">card_giftcard</mat-icon> -->
                            <i class="fa fa-barcode" style="font-size: 60px; color: #f8b3cf;" aria-hidden="true"></i>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-6">
                            <p style="font-size:13px">Pagando
                                contas dos
                                filhos </p>
                        </div>
                    </div>
                </div>
                <div class="col-5"
                    style="border: 1px solid #01b1af; border-radius: 20px; padding: 10px;margin-bottom: 20px;">
                    <div class="row">
                        <div class="col-5">
                            <img src="/assets/img/eventos/22.png" width="100%" alt="">
                        </div>
                        <div class="col-7">
                            <p style="font-size:13px">Assinar
                                clube PIPOOH</p>
                        </div>
                    </div>

                </div>
                <div class="col-1"></div>
                <div class="col-5"
                    style="border: 1px solid #01b1af; border-radius: 20px;margin-bottom: 20px;padding: 10px;">
                    <div class="row">
                        <div class="col-3">
                            <mat-icon style="font-size: 60px; color: #f8b3cf;">shopping_cart</mat-icon>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-6">
                            <p style="font-size:13px">Comprar
                                nos parceiros </p>
                        </div>
                    </div>
                </div>
                <div class="col-5"
                    style="border: 1px solid #01b1af; border-radius: 20px; padding: 10px;margin-bottom: 20px;">
                    <div class="row">
                        <div class="col-3">
                            <i class="fa fa-share" style="font-size: 60px; color: #f8b3cf;" aria-hidden="true"></i>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-6">
                            <p style="font-size:13px">Indicar mães
                                ou lojas </p>
                        </div>
                    </div>

                </div>
                <div class="col-1"></div>
                <div class="col-5"
                    style="border: 1px solid #01b1af; border-radius: 20px;padding: 10px; margin-bottom: 20px;">
                    <div class="row">
                        <div class="col-5">
                            <img src="/assets/img/eventos/36.png" width="100%" alt="">
                        </div>
                        <div class="col-6">
                            <p style="font-size:13px">Desafios
                                de educar e
                                brincar</p>
                        </div>
                    </div>
                </div>

                <div class="col-5"
                    style="border: 1px solid #01b1af; border-radius: 20px; padding: 10px;margin-bottom: 20px;">
                    <div class="row">
                        <div class="col-5">
                            <img src="/assets/img/eventos/24.png" width="100%" alt="">
                        </div>
                        <div class="col-7">
                            <p style="font-size: 13px;">Receber auxílios
                                ou Pensão. </p>
                        </div>
                    </div>

                </div>
                <div class="col-1"></div>
                <div class="col-5"
                    style="border: 1px solid #01b1af; border-radius: 20px; padding: 10px;margin-bottom: 20px; ">
                    <div class="row">
                        <div class="col-4">
                            <img src="/assets/img/eventos/25.png" width="150%" alt="">
                        </div>
                        <div class="col-8">
                            <p style="font-size: 13px;">Doar / vender
                                produtos
                                usados </p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div class="hidden-xs">
            <div class="row" style="padding: 40px; text-align: center;">
                <div class="col-2"></div>
                <div class="col-2"
                    style="border: 1px solid #01b1af; border-radius: 20px; margin-bottom: 20px;padding: 10px; margin-right: 20px;">
                    <div class="row">
                        <div class="col-3">
                            <mat-icon style="font-size: 60px; color: #f8b3cf;">card_giftcard</mat-icon>

                        </div>
                        <div class="col-2"></div>
                        <div class="col-6">
                            <p style="font-size:13px">Ganhando
                                presentes </p>
                        </div>
                    </div>

                </div>
                <div class="col-2"
                    style="border: 1px solid #01b1af; border-radius: 20px; margin-bottom: 20px;padding: 10px; margin-right: 20px;">
                    <div class="row">
                        <div class="col-3">
                            <i class="fa fa-barcode" style="font-size: 60px; color: #f8b3cf;" aria-hidden="true"></i>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-6">
                            <p style="font-size:13px">Pagando
                                contas dos
                                filhos </p>
                        </div>
                    </div>
                </div>
                <div class="col-2"
                    style="border: 1px solid #01b1af; border-radius: 20px; padding: 10px;margin-bottom: 20px; margin-right: 20px;">
                    <div class="row">
                        <div class="col-5">
                            <img src="/assets/img/eventos/22.png" width="80%" alt="">
                        </div>
                        <div class="col-7">
                            <p style="font-size:13px">Assinar
                                clube PIPOOH</p>
                        </div>
                    </div>

                </div>
                <div class="col-2"
                    style="border: 1px solid #01b1af; border-radius: 20px;margin-bottom: 20px;padding: 10px; margin-right: 20px;">
                    <div class="row">
                        <div class="col-3">
                            <mat-icon style="font-size: 60px; color: #f8b3cf;">shopping_cart</mat-icon>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-6">
                            <p style="font-size:13px">Comprar
                                nos parceiros </p>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
                <div class="col-2"></div>
                <div class="col-2"
                    style="border: 1px solid #01b1af; border-radius: 20px; padding: 10px;margin-bottom: 20px;margin-right: 20px;">
                    <div class="row">
                        <div class="col-3">
                            <i class="fa fa-share" style="font-size: 60px; color: #f8b3cf;" aria-hidden="true"></i>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-6">
                            <p style="font-size:13px">Indicar mães
                                ou lojas </p>
                        </div>
                    </div>

                </div>
                <div class="col-2"
                    style="border: 1px solid #01b1af; border-radius: 20px;padding: 10px; margin-bottom: 20px;margin-right: 20px;margin-left: 120;">
                    <div class="row">
                        <div class="col-5">
                            <img src="/assets/img/eventos/36.png" width="80%" alt="">
                        </div>
                        <div class="col-6">
                            <p style="font-size:13px">Desafios
                                de educar e
                                brincar</p>
                        </div>
                    </div>
                </div>

                <div class="col-2"
                    style="border: 1px solid #01b1af; border-radius: 20px; padding: 10px;margin-bottom: 20px;margin-right: 20px;">
                    <div class="row">
                        <div class="col-5">
                            <img src="/assets/img/eventos/24.png" width="80%" alt="">
                        </div>
                        <div class="col-7">
                            <p style="font-size: 13px;">Receber auxílios
                                ou Pensão. </p>
                        </div>
                    </div>

                </div>
                <div class="col-2"
                    style="border: 1px solid #01b1af; border-radius: 20px; padding: 10px;margin-bottom: 20px;margin-right: 20px;">
                    <div class="row">
                        <div class="col-4">
                            <img src="/assets/img/eventos/25.png" width="80%" alt="">
                        </div>
                        <div class="col-8">
                            <p style="font-size: 13px;">Doar / vender
                                produtos
                                usados </p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div id="usar">
            <h3 style="color:#01B1AF">USAR PONTOS</h3>
            <div class="row" style="padding: 25px; margin-left: 5%;margin-right: 5%;">
                <!-- desktop -->
                <div class="col-4 hidden-xs">
                    <a href="https://www.livelo.com.br/home" target="_blank"
                        style="margin: 3px; display: inherit; background-color: #E50091; width: 100%;
                        height: 100%;"
                        class="btn btn-success2 m-btn m-btn--custom btn-sm-block text-white mr-sm-4"><i
                            class="fas fa-plus"></i>
                        Livelo</a>
                </div>
                <div class="col-4 hidden-xs">
                    <a href="https://latampass.latam.com/" target="_blank"
                        style="margin: 3px; display: inherit; background-color: #140062; width: 100%;
                        height: 100%;"
                        class="btn btn-success2 m-btn m-btn--custom btn-sm-block text-white mr-sm-4"><i
                            class="fas fa-plus"></i>
                        Latam</a>
                </div>
                <div class="col-4 hidden-xs">
                    <a href="https://www.pulpa.com.br/" target="_blank"
                        style="margin: 3px; display: inherit; background-color: #7000cc; width: 100%;
                        height: 100%;"
                        class="btn btn-success2 m-btn m-btn--custom btn-sm-block text-white mr-sm-4"><i
                            class="fas fa-plus"></i>
                        Previdência</a>
                </div>
                <!-- mobile -->
                <div class="col-12 visible-xs">
                    <a href="https://www.livelo.com.br/home" target="_blank"
                        style="margin: 3px; display: inherit; background-color: #E50091;"
                        class="btn btn-success2 m-btn m-btn--custom btn-sm-block text-white mr-sm-4"><i
                            class="fas fa-plus"></i>
                        Livelo</a>
                </div>
                <div class="col-12 visible-xs">
                    <a href="https://latampass.latam.com/" target="_blank"
                        style="margin: 3px; display: inherit; background-color: #140062;"
                        class="btn btn-success2 m-btn m-btn--custom btn-sm-block text-white mr-sm-4"><i
                            class="fas fa-plus"></i>
                        Latam</a>
                </div>
                <div class="col-12 visible-xs">
                    <a href="https://www.pulpa.com.br/" target="_blank"
                        style="margin: 3px; display: inherit; background-color: #7000cc;"
                        class="btn btn-success2 m-btn m-btn--custom btn-sm-block text-white mr-sm-4"><i
                            class="fas fa-plus"></i>
                        Previdência</a>
                </div>
            </div>
            <br>
            <p>R$ 2,00 = 1 ponto Livelo/Latam <br>
                100 pontos Pipooh = 1 ponto Livelo/Latam <br>
                3500 pontos Pipooh = R$ 1,00 Previdência
            </p>
            <!-- <p>100 pontos Pipooh = 1 ponto Livelo/Latam</p>
            <p>3500 pontos Pipooh = R$ 1,00 Previdência</p> -->
            <div class="line-pont"></div>
            

        </div>
    </div>
    <div class="row">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">
            <div class="info-dropdown" *ngFor="let drop of dropdown; let i = index">
                <a (click)="drop.status = 1" style="color: #01b1af; text-align: center;"
                    *ngIf="drop.status == 0"><i class="fa fa-plus" aria-hidden="true"></i>
                    {{drop.titulo}}</a>
                <a (click)="drop.status = 0" style="color: #01b1af; text-align: center;"
                    *ngIf="drop.status == 1"><i class="fa fa-minus" aria-hidden="true"></i>
                    {{drop.titulo}}</a>

                <p *ngIf="drop.status == 1" [innerHtml]="drop.descricao">
                </p>
            </div>
        </div>
    </div>

</div>

<app-footer></app-footer>