<div class="container" style="text-align: center;background-color: #fff;">

    <img src="https://img.pipooh.com.br/assets/img/cabecalho.png" class="visible-xs" width="100%">
    <img src="https://img.pipooh.com.br/assets/img/cabecalho.png" class="hidden-xs" width="50%">

    <div style="background-color: #fff; padding: 50px 30px;border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px; text-align: left;">

        <p>Olá, {{pesquisa.nomeParticipante}}!
        <p>

        <p>
            Ficamos muito felizes em ter você como beneficiário. <br>
            O nosso objetivo é atender sempre da melhor forma.
        </p>
        <p *ngIf="pesquisa.participanteNPS == true">Obrigado, você já respondeu nossa pesquisa.</p>
        <p *ngIf="pesquisa.participanteNPS == false">Pode nos ajudar a melhorar a experiência dos eventos? Vai levar menos de 2 minutos!</p>
        <p *ngIf="pesquisa.participanteNPS == false">Pensando na sua experiência com o serviço do {{pesquisa.nomeEvento}}, em uma escala
            de 0 a 10, o quanto você recomendaria o serviço para amigos e familiares?</p>
        <div class="row" *ngIf="pesquisa.participanteNPS == false">
            <table align="left" border="0" cellpadding="0" cellspacing="0" style="margin-bottom: 30px;">
                <tbody>
                    <tr>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 0"
                            style="width:50px;height:50px;border-radius:5px;background:#b72025; cursor: pointer;">

                            <a (click)="nota = 0" style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block;
                                width:100%;height:50px;border-radius:5px;background:#b72025;color: white; ">
                                0
                            </a>
                        </td>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 1"
                            style="width:50px;height:50px;border-radius:5px;background:#d62027;cursor: pointer;">
                            <a (click)="nota = 1"
                                style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block">
                                <span style="color:#ffffff">1</span>
                            </a>
                        </td>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 2"
                            style="width:50px;height:50px;border-radius:5px;background:#f05223;cursor: pointer;">
                            <a
                                style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block">
                                <span style="color:#ffffff">2</span>
                            </a>
                        </td>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 3"
                            style="width:50px;height:50px;border-radius:5px;background:#f36f21;cursor: pointer;">
                            <a
                                style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block">
                                <span style="color:#ffffff">3</span>
                            </a>
                        </td>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 4"
                            style="width:50px;height:50px;border-radius:5px;background:#faa823;cursor: pointer;">
                            <a
                                style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block">
                                <span style="color:#ffffff">4</span>
                            </a>
                        </td>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 5"
                            style="width:50px;height:50px;border-radius:5px;background:#ffca27;cursor: pointer;">
                            <a
                                style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block">
                                <span style="color:#ffffff">5</span>
                            </a>
                        </td>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 6"
                            style="width:50px;height:50px;border-radius:5px;background:#ecdb12;cursor: pointer;">
                            <a
                                style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block">
                                <span style="color:#ffffff">6</span>
                            </a>
                        </td>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 7"
                            style="width:50px;height:50px;border-radius:5px;background:#dedd37;cursor: pointer;">
                            <a
                                style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block">
                                <span style="color:#ffffff">7</span>
                            </a>
                        </td>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 8"
                            style="width:50px;height:50px;border-radius:5px;background:#c5d92d;cursor: pointer;">
                            <a
                                style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block">
                                <span style="color:#ffffff">8</span>
                            </a>
                        </td>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 9"
                            style="width:50px;height:50px;border-radius:5px;background:#afd136;cursor: pointer;">
                            <a
                                style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block">
                                <span style="color:#ffffff">9</span>
                            </a>
                        </td>
                        <td style="width:2px;height:50px;line-height:0;font-size:0" width="4" height="50"></td>
                        <td align="center" valign="middle" (click)="nota = 10"
                            style="width:50px;height:50px;border-radius:5px;background:#64b64d;cursor: pointer;">
                            <a
                                style="font-size:16px;font-weight:bold;font-family:Helvetica Neue,Helvetica,Arial,sans-serif;text-decoration:none;line-height:50px;display:block">
                                <span style="color:#ffffff">10</span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="nota >= 0 && pesquisa.participanteNPS == false">
            <p *ngIf="nota >= 0 && nota <= 6"><b>Vimos que você não ficou satisfeito :( </b> <br>
                Conta pra gente se você aceita receber um contato para falar mais sobre sua experiência e qual foi o principal motivo da sua nota acima.</p>
            <!-- <ul class="categorias" style="list-style: none;" *ngIf="nota >= 0 && nota <= 6">
                <li class="mb-1" *ngFor="let op of perguntasDetratores">
                    <mat-checkbox [(ngModel)]="op.check">
                    </mat-checkbox><span style="color: #01b1af;"> {{op.pergunta}}</span>
                </li>

            </ul> -->
            <p *ngIf="nota >= 7 && nota <= 8"><b>Ainda não conseguimos encantar você </b> <br>
                Conta pra gente qual foi o principal motivo da sua nota acima:</p>
            <!-- <ul class="categorias" style="list-style: none;" *ngIf="nota >= 7 && nota <= 8">
                <li class="mb-1" *ngFor="let op of perguntasNeutros">
                    <mat-checkbox [(ngModel)]="op.check">
                    </mat-checkbox><span style="color: #01b1af;"> {{op.pergunta}}</span>
                </li>

            </ul> -->
            <p *ngIf="nota >= 9 && nota <= 10"><b>Que bom que você teve uma boa experiência!</b> <br>
                Conta pra gente qual foi o principal motivo da sua nota acima:</p>
            <!-- <ul class="categorias" style="list-style: none;" *ngIf="nota >= 9 && nota <= 10">
                <li class="mb-1" *ngFor="let op of perguntasPromotores">
                    <mat-checkbox [(ngModel)]="op.check">
                    </mat-checkbox><span style="color: #01b1af;"> {{op.pergunta}}</span>
                </li>

            </ul> -->
            <br>
            <h5><b> Como podemos melhorar?</b> </h5>
            <p>Por questões de LGPD (Lei Geral de Proteção de Dados), por favor, não inclua dados pessoais no campo
                abaixo como: nome, nº da carteira, CPF, RG.</p>
            <textarea name="" id="" cols="30" rows="10" [(ngModel)]="comoMelhorar" class="form-control">

            </textarea>
            <br>
            <p>Eventualmente, podemos entrar em contato para falar a respeito da sua opinião. Você aceita?</p>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="aceitaContato">
                <mat-radio-button value="Sim">Sim</mat-radio-button>
                &nbsp; <mat-radio-button value="Não">Não</mat-radio-button>
              </mat-radio-group>
            <br><br>
            <div class="col-lg-12">
                <button class="btn btn-success2" style="float: right; width: 50%;" (click)="inserirNPS()"
                [disabled]="loading" 
            [ngClass]="{'m-loader m-loader--right m-loader--light': loading }">Enviar</button>
            </div>
        </div>
        <br>


       
        <p style="margin-top:10px;"><i>Equipe Pipooh</i><br /><a href="https://pipooh.com.br">www.pipooh.com.br</a></p>
    </div>
</div>